import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import logo from '../media/ERateCentralLogo.png';
import ClientLogoAndHeaders from './ClientLogoAndHeaders.js';

const CACHE_INCREMENT = 1;
const CLIENT_CACHE_NAME = 'ced-client-funding-summary-' + CACHE_INCREMENT;

export default function ClientFundingSummary(props) {
    const clientId = props.clientId;
    const kyList = props.kyList;
    const spin = props.spin;
    const state = props.state;
    const searchType = props.searchType;
    const clientAPI = props.clientAPI;
    const clientDataKey = `/ClientList/funding-summary/client-data/${clientId}/${kyList}/${searchType}`;
    const contactDataKey = `/ClientList/funding-summary/contact-data/${clientId}/${kyList}/${searchType}`;
    const ecfCacheKey = `/ClientList/funding-summary/ecf/${clientId}/${kyList}/${searchType}`;
    const frnCacheKey = `/ClientList/funding-summary/frn/${clientId}/${kyList}/${searchType}`;
    const [clientData, setClientData] = React.useState(null);
    const [clientECFData, setClientECFData] = React.useState(null);
    const [clientFRNData, setClientFRNData] = React.useState(null);
    const [serviceProviderDisplayName, setServiceProviderDisplayName] = React.useState('');
    const [clientContactData, setClientContactData] = React.useState(null);
    const [specificClientContactObj, setSpecificClientContactObj] = React.useState(null);
    let navigate = useNavigate();

    React.useEffect(() => {
        const cacheData = async (key, data) => {
            try {
                if (data) {
                    let APICache = await caches.open(CLIENT_CACHE_NAME);
                    let headersOptions = { headers: { 'Content-Type': 'application/json' } };
                    await APICache.put(key, new Response(JSON.stringify(data), headersOptions));
                    console.log(`[ClientFundingSummary][useEffect][cacheData] Data cached under key: ${key}`);
                }
            } catch (error) {
                console.error(
                    `[ClientFundingSummary][useEffect][cacheData] Error caching data under key: ${key}`,
                    error
                );
            }
        };

        const checkCacheForPreviousData = async (clientKey, contactKey, ecfCacheKey, frnCacheKey) => {
            let APICache = await caches.open(CLIENT_CACHE_NAME);

            // Check for cached Client data
            const clientResponse = await APICache.match(clientKey);
            console.log(
                '1) [ClientFundingSummary][useEffect][checkCacheForPreviousData] clientResponse:',
                clientResponse
            );

            if (clientResponse !== undefined) {
                const cachedClientData = await clientResponse.json();
                setClientData(cachedClientData);
                console.log(
                    '2) [ClientFundingSummary][useEffect][checkCacheForPreviousData] cachedClientData (cache found):',
                    cachedClientData
                );
            }

            // Check for cached Contact data
            const contactResponse = await APICache.match(contactKey);
            console.log(
                '3) [ClientFundingSummary][useEffect][checkCacheForPreviousData] contactResponse:',
                contactResponse
            );

            if (contactResponse !== undefined) {
                const cachedContactData = await contactResponse.json();
                setClientContactData(cachedContactData);
                setSpecificClientContactObj(getClientContactDataObj(cachedContactData));

                console.log(
                    '4) [ClientFundingSummary][useEffect][checkCacheForPreviousData] cachedContactData (cache found):',
                    cachedContactData
                );
            }

            // Check for cached ECF data
            const ecfResponse = await APICache.match(ecfCacheKey);
            let cachedECFData = [];
            console.log('5) [ClientFundingSummary][useEffect][checkCacheForPreviousData] ecfResponse:', ecfResponse);

            if (ecfResponse !== undefined) {
                cachedECFData = await ecfResponse.json();
                setClientECFData(cachedECFData);
                console.log(
                    '6) [ClientFundingSummary][useEffect][checkCacheForPreviousData] cachedECFData (cache found):',
                    cachedECFData
                );
            }

            // Check for cached FRN data
            const frnResponse = await APICache.match(frnCacheKey);
            let cachedFRNData = {};
            console.log('7) [ClientFundingSummary][useEffect][checkCacheForPreviousData] frnResponse:', frnResponse);

            if (frnResponse !== undefined) {
                cachedFRNData = await frnResponse.json();
                setClientFRNData(cachedFRNData);
                console.log(
                    '8) [ClientFundingSummary][useEffect][checkCacheForPreviousData] cachedFRNData (cache found):',
                    cachedFRNData
                );
            }

            if (cachedFRNData || cachedECFData) {
                setServiceProviderDisplayName(getServiceProviderName(cachedFRNData, cachedECFData));
            }

            // Return true if any data was found in the cache
            if (
                clientResponse !== undefined ||
                contactResponse !== undefined ||
                ecfResponse !== undefined ||
                frnResponse !== undefined
            ) {
                console.log('9) [ClientFundingSummary][useEffect][checkCacheForPreviousData] didWeUseCache:', true);
            } else {
                console.log('9) [ClientFundingSummary][useEffect][checkCacheForPreviousData] didWeUseCache:', false);
            }
        };

        const getClientData = async () => {
            const clientdata = await clientAPI.GetClient(clientId);
            if (!clientdata) return;
            setClientData(clientdata);

            await cacheData(clientDataKey, clientdata);
            console.log('10) [ClientFundingSummary][useEffect][getClientData] clientdata:', clientdata);
            return clientdata;
        };

        const getClientContactData = async (kyList) => {
            const clientContactDataVar = await clientAPI.GetClientContactDataOriginal(kyList);
            setClientContactData(clientContactDataVar);

            await cacheData(contactDataKey, clientContactDataVar); // Cache the contact data
            console.log(
                '11) [ClientFundingSummary][useEffect][getClientContactData] clientContactDataVar:',
                clientContactDataVar
            );
            return clientContactDataVar;
        };

        const getFRNData = async (searchType, kyList, state) => {
            let frnDataResponse = [];
            try {
                if (spin) {
                    frnDataResponse = await clientAPI.GetClientFRNData(searchType, spin, state);
                } else {
                    frnDataResponse = await clientAPI.GetClientFRNData(searchType, kyList, state);
                }
                console.log('12) [ClientFundingSummary][useEffect][getFRNData] frnDataResponse:', frnDataResponse);
            } catch (error) {
                console.error(error);
                toast.error(error);
            }
            return frnDataResponse;
        };

        const getECFData = async (searchType, kyList, state) => {
            let ecfDataResponse = [];
            try {
                ecfDataResponse = await clientAPI.GetClientECFData(searchType, kyList, state, null);
                console.log('13) [ClientFundingSummary][useEffect][getECFData] ecfDataResponse:', ecfDataResponse);
            } catch (error) {
                console.log(error);
                toast.error(error);
            }
            return ecfDataResponse;
        };

        const getServiceProviderName = (allFRNData, allECFData) => {
            const extractFromFRN = (data) => {
                if (!data || Object.keys(data).length === 0) return null;

                const sortedYears = Object.keys(data).sort((a, b) => b - a);

                for (const year of sortedYears) {
                    const yearData = data[year];
                    if (yearData && yearData.serviceprovidername) {
                        return yearData.serviceprovidername;
                    }
                }

                return null;
            };

            const extractFromECF = (data) => {
                if (!data || Object.keys(data).length === 0) return null;

                let dataArray = Object.values(data);
                dataArray.sort((a, b) => b.filingwindow - a.filingwindow);

                for (const obj of dataArray) {
                    if (obj && obj.serviceprovidername) {
                        return obj.serviceprovidername;
                    }
                }

                return null;
            };

            // If not found, try FRN data
            const serviceProviderNameFRN = extractFromFRN(allFRNData);
            if (serviceProviderNameFRN) return serviceProviderNameFRN;

            // Try to get serviceprovidername from ECF data first
            const serviceProviderNameECF = extractFromECF(allECFData);
            return serviceProviderNameECF || 'N/A';
        };

        // Trying to get the first available contact info
        const getClientContactDataObj = (allClientContactData) => {
            // Get all the keys of the object
            const keys = Object.keys(allClientContactData);

            if (keys.length === 0) {
                return null;
            }

            // Loop through the keys in their current order (most recent first)
            for (let i = 0; i < keys.length; i++) {
                const dataArray = allClientContactData[keys[i]];

                // If the array is not empty, return the first object
                if (dataArray && dataArray.length > 0) {
                    console.log(
                        '[ClientFundingSummary][useEffect][getClientContactDataObj] specificClientContactDataObj:',
                        dataArray[0]
                    );
                    return dataArray[0]; // Return the first non-empty object
                }
            }

            return null;
        };

        // Helper function to conditionally fetch data and cache it
        const fetchAndCacheData = async (searchType, kyList, state, getDataFunction, setDataFn, cacheKey) => {
            let dataResponse = [];

            dataResponse = await getDataFunction(searchType, kyList, state);
            setDataFn(dataResponse);

            // ECF data comes as an array, FRN comes as an object
            if (dataResponse && (dataResponse.length > 0 || Object.keys(dataResponse).length > 0)) {
                await cacheData(cacheKey, dataResponse);
            }

            return dataResponse;
        };

        const getAllData = async () => {
            // Check if the cache for the clientId, BEN, and searchType combo already exists or not
            await checkCacheForPreviousData(clientDataKey, contactDataKey, ecfCacheKey, frnCacheKey);
            console.log('[ClientFundingSummary][useEffect][getAllData] backgroundRefreshStarting...');

            // Fetch and cache client and contact data only if not using cached data
            const clientdata = await getClientData();
            if (!clientdata) return; // Ensure client data exists for the subsequent logic

            const clientContactDataTemp = await getClientContactData(kyList);
            if (clientContactDataTemp) {
                setSpecificClientContactObj(getClientContactDataObj(clientContactDataTemp));
            }

            // Fetch ECF and FRN data depending on the searchType
            switch (searchType) {
                case 'entNum': {
                    const stateParam = state || null;

                    await fetchAndCacheData('entNum', kyList, stateParam, getFRNData, setClientFRNData, frnCacheKey);
                    await fetchAndCacheData('entNum', kyList, stateParam, getECFData, setClientECFData, ecfCacheKey);
                    break;
                }
                case 'spin': {
                    const spinParam = spin || kyList;

                    const frnDataTemp = await fetchAndCacheData(
                        'spin',
                        kyList,
                        clientdata.state_abbrev,
                        getFRNData,
                        setClientFRNData,
                        frnCacheKey
                    );

                    const ecfDataTemp = await fetchAndCacheData(
                        'spin',
                        spinParam,
                        clientdata.state_abbrev,
                        getECFData,
                        setClientECFData,
                        ecfCacheKey
                    );

                    setServiceProviderDisplayName(getServiceProviderName(frnDataTemp, ecfDataTemp));
                    break;
                }
                default:
                    console.log('[ClientFundingSummary][useEffect][getAllData] Invalid searchType:', searchType);
            }
            console.log('[ClientFundingSummary][useEffect][getAllData] backgroundRefreshEnding...');
        };

        getAllData();
    }, [clientAPI, clientDataKey, clientId, contactDataKey, ecfCacheKey, frnCacheKey, kyList, searchType, spin, state]);

    // Don't display anything until we have data.
    if (
        !clientData ||
        !clientECFData ||
        !clientFRNData ||
        (searchType === 'entNum' && (!clientContactData || !specificClientContactObj)) // SPIN may not have contact data
    ) {
        return (
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Link href='https://e-ratecentral.com' target='_blank' rel='noreferrer'>
                            <img
                                height='100px'
                                src={logo}
                                alt='Logo'
                                style={{ cursor: 'pointer', marginTop: '-18.5px' }}
                            />
                        </Link>
                        <Link
                            sx={{ fontSize: '0.875rem', marginRight: '38%', marginTop: '-5%' }}
                            href='https://e-ratecentral.com'
                            target='_blank'
                            rel='noreferrer'
                        >
                            {'https://e-ratecentral.com'}
                        </Link>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress />
                    <Typography variant='body1' sx={{ color: 'gray', marginTop: '10px', textAlign: 'center' }}>
                        <em>
                            Gathering data for Emergency Connectivity Fund Utilization & E-Rate Utilization Summary
                            Charts...
                        </em>
                    </Typography>
                </Box>
            </Box>
        );
    }

    const createYearsOptions = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11

        const startYear = 2016;
        const years = [];

        for (let year = startYear; year <= currentYear; year++) {
            if (year < currentYear || (year === currentYear && currentMonth >= 6)) {
                years.push(`FY ${year} Detail`);
            }
        }

        return years.sort((a, b) => {
            const yearA = typeof a === 'string' ? parseInt(a.match(/\d+/)) : a;
            const yearB = typeof b === 'string' ? parseInt(b.match(/\d+/)) : b;
            return yearB - yearA;
        });
    };
    const years = createYearsOptions();
    if (searchType !== 'spin') {
        years.unshift('All Years Detail');
    }
    years.unshift('All Years Summary');

    const handleFundingYearSelectionChange = (event, value) => {
        let newYear = event.target.value;

        if (newYear === 'All Years Summary') {
            navigate(
                `/client-list/funding-summary/${clientId}/${searchType === 'entNum' ? kyList : spin}/${searchType}`
            );
        } else {
            // navigate(`/client-list/funding-year-details/${clientId}/${kyList}/${newYear}/${searchType}`);
            newYear = newYear === 'All Years Detail' ? 'All' : newYear.replace('FY', '').replace('Detail', '').trim();
            navigate(
                `/client-list/funding-year-details/${clientId}/${
                    searchType === 'entNum' ? kyList : spin ? spin : kyList
                }/${newYear}/${searchType}`
            );
        }
    };

    return (
        <Box>
            {searchType === 'spin' ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Link href='https://e-ratecentral.com' target='_blank' rel='noreferrer'>
                            <img
                                height='100px'
                                src={logo}
                                alt='Logo'
                                style={{ cursor: 'pointer', marginTop: '-18.5px' }}
                            />
                        </Link>
                        <Link
                            sx={{ fontSize: '0.875rem', marginRight: '38%', marginTop: '-5%' }}
                            href='https://e-ratecentral.com'
                            target='_blank'
                            rel='noreferrer'
                        >
                            {'https://e-ratecentral.com'}
                        </Link>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontSize: '0.875rem',
                            flex: 1,
                            marginRight: '10%',
                        }}
                    >
                        <Box sx={{ width: 'fit-content', textAlign: 'left' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <h3 style={{ margin: 0 }}>
                                    Service Provider:{' '}
                                    <span style={{ fontWeight: 'normal' }}>{serviceProviderDisplayName}</span>
                                </h3>
                            </Box>
                            <Box sx={{ marginTop: 0 }}>
                                <h3 style={{ margin: 0 }}>
                                    SPIN{kyList.includes(',') ? 's' : ''}:{' '}
                                    <span style={{ fontWeight: 'normal' }}>{spin ? spin : kyList}</span>
                                </h3>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <ClientLogoAndHeaders
                    searchType={searchType}
                    clientId={clientId}
                    clientData={clientData}
                    kyList={kyList}
                    specificClientContactObj={specificClientContactObj}
                />
            )}

            <Box sx={{ flexBasis: '700px', marginTop: 2 }}>
                <ClientFundingSummarySection
                    clientId={clientId}
                    kyList={kyList}
                    spin={spin}
                    searchType={searchType}
                    clientECFData={clientECFData}
                    clientFRNData={clientFRNData}
                    handleFundingYearSelectionChange={handleFundingYearSelectionChange}
                    years={years}
                />
            </Box>
        </Box>
    );
}

function ClientFundingSummarySection({
    clientId,
    kyList,
    spin,
    searchType,
    clientECFData,
    clientFRNData,
    selectedYear,
    handleFundingYearSelectionChange,
    years,
}) {
    const [ecfOrder, setECFOrder] = React.useState('asc');
    const [frnOrder, setFRNOrder] = React.useState('asc');
    const [ecfOrderBy, setECFOrderBy] = React.useState('');
    const [frnOrderBy, setFRNOrderBy] = React.useState('');
    const [newClientECFData, setNewClientECFData] = React.useState([]);

    React.useEffect(() => {
        const processedECFResults = processAllECFData(clientECFData);
        console.log('[ClientFundingSummarySection][useEffect] processedECFResults: ', processedECFResults);

        setNewClientECFData(processedECFResults);
    }, [clientECFData, clientFRNData]);

    // Don't display anything until we have data.
    if (!clientECFData || !clientFRNData) {
        return null;
    }

    const parseMoney = (value) => {
        if (!value || value == null) {
            return 0;
        }
        if (typeof value === 'number') {
            return value;
        }

        if (value.includes('%')) {
            return parseFloat(value.replace('%', '')) / 100;
        }
        return parseFloat(value.replace(/[$,]/g, ''));
    };

    const formatMoney = (value) => {
        // Checking if the value is 0 or "0"
        if (value === 0 || value === '0' || !value) {
            return '$0.00';
        }

        // Ensuring the value is a number
        const numericValue = parseFloat(value);

        // Formatting the number to money
        return `$${numericValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    };

    const ecfHeaders = [
        { id: 'filingwindow', label: 'Filing Window' },
        { id: 'ecfFRNCountTotal', label: 'Requested FRNs' },
        { id: 'ecfFRNFundedCountTotal', label: 'Funded FRNs' },
        { id: 'ecfOriginalRequestTotal', label: 'Requested Amount' },
        { id: 'ecfSubtotalEquipmentTotal', label: 'Committed Equipment' },
        { id: 'ecfSubtotalServicesTotal', label: 'Committed Services' },
        { id: 'ecfCommittedRequestTotal', label: 'Total Committed' },
        { id: 'ecfDisbursedTotal', label: 'Total Disbursed' },
        { id: 'ecfRemainingTotal', label: 'Remaining Balance' },
        { id: 'ecfUtilized', label: 'Util.%' },
    ];

    const frnHeaders = [
        { id: 'fundingYear', label: 'Funding Year' },
        { id: 'numReq', label: 'Requested FRNs' },
        { id: 'numFunded', label: 'Funded FRNs' },
        { id: 'num486', label: '486 on File' },
        { id: 'amtOrigReq', label: 'Requested Amount' },
        { id: 'amtPriority1', label: 'Category 1' },
        { id: 'amtPriority2', label: 'Category 2' },
        { id: 'amtComm', label: 'Total Committed' },
        { id: 'amtDisb', label: 'Total Disbursed' },
        { id: 'amtBal', label: 'Remaining Balance' },
        { id: 'pcUtil', label: 'Util.%' },
    ];

    const handleECFRequestSort = (property) => {
        const isAsc = ecfOrderBy === property && ecfOrder === 'asc';
        setECFOrder(isAsc ? 'desc' : 'asc');
        setECFOrderBy(property);
    };

    const handleFRNRequestSort = (property) => {
        const isAsc = frnOrderBy === property && frnOrder === 'asc';
        setFRNOrder(isAsc ? 'desc' : 'asc');
        setFRNOrderBy(property);
    };

    const sortedECFData = [...newClientECFData].sort((a, b) => {
        if (ecfOrderBy) {
            let aValue = a[ecfOrderBy];
            let bValue = b[ecfOrderBy];

            // Convert monetary values to numbers for sorting
            if (
                ecfOrderBy.startsWith('ecfOriginalRequestTotal') ||
                ecfOrderBy.startsWith('ecfSubtotalEquipmentTotal') ||
                ecfOrderBy.startsWith('ecfSubtotalServicesTotal') ||
                ecfOrderBy.startsWith('ecfCommittedRequestTotal') ||
                ecfOrderBy.startsWith('ecfDisbursedTotal') ||
                ecfOrderBy.startsWith('ecfRemainingTotal')
            ) {
                aValue = parseFloat(aValue.toString().replace(/[$,]/g, ''));
                bValue = parseFloat(bValue.toString().replace(/[$,]/g, ''));
            }

            // Convert utilization percentage to number for sorting
            if (ecfOrderBy === 'ecfUtilized') {
                aValue = parseFloat(aValue);
                bValue = parseFloat(bValue);
            }

            // Handle sorting based on the value type
            if (typeof aValue === 'number') {
                return ecfOrder === 'asc' ? aValue - bValue : bValue - aValue;
            } else if (typeof aValue === 'string') {
                return ecfOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
        }
        return 0;
    });

    const sortedFRNData = Object.keys(clientFRNData)
        .filter((key) => key !== 'totals')
        .sort((a, b) => b - a) // Sorting years in descending order
        .map((year) => ({
            year, // Include the year as a property
            ...clientFRNData[year],
        }))
        .sort((a, b) => {
            if (frnOrderBy) {
                let aValue = a[frnOrderBy];
                let bValue = b[frnOrderBy];

                if (frnOrderBy === 'fundingYear') {
                    aValue = parseInt(a.year);
                    bValue = parseInt(b.year);
                    return frnOrder === 'asc' ? aValue - bValue : bValue - aValue;
                }

                // Convert monetary values or percentages to numbers for sorting
                if (frnOrderBy.startsWith('amt') || frnOrderBy === 'pcUtil') {
                    aValue = parseMoney(aValue);
                    bValue = parseMoney(bValue);
                }

                // Handle sorting based on the value type
                if (typeof aValue === 'number') {
                    return frnOrder === 'asc' ? aValue - bValue : bValue - aValue;
                } else if (typeof aValue === 'string') {
                    return frnOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                }
            }
            return 0;
        });

    const processEachECFObj = (ea_window_data) => {
        if (searchType === 'entNum') {
            let acc = {
                ecfUtilized: 0,
                ecfRemaining: 0,
                ecfFRNCountTotal: 0,
                ecfFRNFundedCountTotal: 0,
                ecfOriginalRequestTotal: 0,
                ecfSubtotalEquipmentTotal: 0,
                ecfSubtotalServicesTotal: 0,
                ecfCommittedRequestTotal: 0,
                ecfDisbursedTotal: 0,
                ecfRemainingTotal: 0,
            };

            // Calculate Utilization Percentage
            if (ea_window_data.committedrequest > 0 && ea_window_data.disbursement > 0) {
                acc.ecfUtilized = parseFloat(ea_window_data.disbursement / ea_window_data.committedrequest);
            } else {
                acc.ecfUtilized = 0;
            }

            // Calculate Remaining Balance
            if (ea_window_data.committedrequest > 0) {
                if (ea_window_data.disbursement > 0) {
                    acc.ecfRemaining = parseFloat(ea_window_data.committedrequest - ea_window_data.disbursement);
                } else {
                    acc.ecfRemaining = parseFloat(ea_window_data.committedrequest);
                }
            }

            // ECF Totals Calculation
            acc.ecfFRNCountTotal = ea_window_data.frncount || 0;
            acc.ecfFRNFundedCountTotal = ea_window_data.frncountcommitted || 0;
            acc.ecfOriginalRequestTotal = parseFloat(ea_window_data.originalrequest) || 0;
            acc.ecfSubtotalEquipmentTotal = parseFloat(ea_window_data.subtotalequipment) || 0;
            acc.ecfSubtotalServicesTotal = parseFloat(ea_window_data.subtotalservices) || 0;
            acc.ecfCommittedRequestTotal = parseFloat(ea_window_data.committedrequest) || 0;
            acc.ecfDisbursedTotal = parseFloat(ea_window_data.disbursement) || 0;
            acc.ecfRemainingTotal = acc.ecfCommittedRequestTotal - acc.ecfDisbursedTotal;

            return acc;
        } else if (searchType === 'spin') {
            if (ea_window_data.filingwindow === 'ECF Window 1') {
                let acc = {
                    ecfUtilized: 0,
                    ecfRemaining: 0,
                    ecfFRNCountTotal: 0,
                    ecfFRNFundedCountTotal: 0,
                    ecfOriginalRequestTotal: 0,
                    ecfSubtotalEquipmentTotal: 0,
                    ecfSubtotalServicesTotal: 0,
                    ecfCommittedRequestTotal: 0,
                    ecfDisbursedTotal: 0,
                    ecfRemainingTotal: 0,
                };

                // Calculate Utilization Percentage
                if (ea_window_data.currentfrnapprovedamount > 0 && ea_window_data.disbursement > 0) {
                    acc.ecfUtilized = parseFloat(ea_window_data.disbursement / ea_window_data.currentfrnapprovedamount); // ECF Window 1 = currentfrnapprovedamount,
                } else {
                    acc.ecfUtilized = 0;
                }

                // Calculate Remaining Balance
                if (ea_window_data.currentfrnapprovedamount > 0) {
                    if (ea_window_data.disbursement > 0) {
                        acc.ecfRemaining = parseFloat(
                            ea_window_data.currentfrnapprovedamount - ea_window_data.disbursement
                        );
                    } else {
                        acc.ecfRemaining = parseFloat(ea_window_data.currentfrnapprovedamount);
                    }
                }

                // ECF Totals Calculation
                acc.ecfFRNCountTotal = ea_window_data.frncount || 0;
                acc.ecfFRNFundedCountTotal = ea_window_data.frncountcommitted || 0;
                acc.ecfOriginalRequestTotal = parseFloat(ea_window_data.originalrequest) || 0;
                acc.ecfSubtotalEquipmentTotal = parseFloat(ea_window_data.subtotalequipment) || 0;
                acc.ecfSubtotalServicesTotal = parseFloat(ea_window_data.subtotalservices) || 0;
                acc.ecfCommittedRequestTotal = parseFloat(ea_window_data.currentfrnapprovedamount) || 0; // If SPIN + window 1, use currentfrnapprovedamount instead of committedrequest. Maybe change name and handle it?
                acc.ecfDisbursedTotal = parseFloat(ea_window_data.disbursement) || 0;
                acc.ecfRemainingTotal = acc.ecfCommittedRequestTotal - acc.ecfDisbursedTotal;

                return acc;
            } else {
                let acc = {
                    ecfUtilized: 0,
                    ecfRemaining: 0,
                    ecfFRNCountTotal: 0,
                    ecfFRNFundedCountTotal: 0,
                    ecfOriginalRequestTotal: 0,
                    ecfSubtotalEquipmentTotal: 0,
                    ecfSubtotalServicesTotal: 0,
                    ecfCommittedRequestTotal: 0,
                    ecfDisbursedTotal: 0,
                    ecfRemainingTotal: 0,
                };

                // Calculate Utilization Percentage
                if (ea_window_data.committedrequest > 0 && ea_window_data.disbursement > 0) {
                    acc.ecfUtilized = parseFloat(ea_window_data.disbursement / ea_window_data.committedrequest); // ECF Window 2, 3 = committedrequest,
                } else {
                    acc.ecfUtilized = 0;
                }

                // Calculate Remaining Balance
                if (ea_window_data.committedrequest > 0) {
                    if (ea_window_data.disbursement > 0) {
                        acc.ecfRemaining = parseFloat(ea_window_data.committedrequest - ea_window_data.disbursement);
                    } else {
                        acc.ecfRemaining = parseFloat(ea_window_data.committedrequest);
                    }
                }

                // ECF Totals Calculation
                acc.ecfFRNCountTotal = ea_window_data.frncount || 0;
                acc.ecfFRNFundedCountTotal = ea_window_data.frncountcommitted || 0;
                acc.ecfOriginalRequestTotal = parseFloat(ea_window_data.originalrequest) || 0;
                acc.ecfSubtotalEquipmentTotal = parseFloat(ea_window_data.subtotalequipment) || 0;
                acc.ecfSubtotalServicesTotal = parseFloat(ea_window_data.subtotalservices) || 0;
                acc.ecfCommittedRequestTotal = parseFloat(ea_window_data.committedrequest) || 0; // If SPIN + window 1, use currentfrnapprovedamount instead of committedrequest. Maybe change name and handle it?
                acc.ecfDisbursedTotal = parseFloat(ea_window_data.disbursement) || 0;
                acc.ecfRemainingTotal = acc.ecfCommittedRequestTotal - acc.ecfDisbursedTotal;

                return acc;
            }
        }
    };

    // Handles multiple ECF Window duplicates (due to multiple BENs being provided)
    const processAllECFData = (ecfData) => {
        const groupedData = ecfData.reduce((acc, ea_obj) => {
            const { filingwindow } = ea_obj;

            if (!acc[filingwindow]) {
                acc[filingwindow] = {
                    filingwindow,
                    ecfUtilized: 0,
                    ecfRemaining: 0,
                    ecfFRNCountTotal: 0,
                    ecfFRNFundedCountTotal: 0,
                    ecfOriginalRequestTotal: 0,
                    ecfSubtotalEquipmentTotal: 0,
                    ecfSubtotalServicesTotal: 0,
                    ecfCommittedRequestTotal: 0,
                    ecfDisbursedTotal: 0,
                    ecfRemainingTotal: 0,
                };
            }

            const processedData = processEachECFObj(ea_obj);

            // Aggregate the data
            acc[filingwindow].ecfUtilized += processedData.ecfUtilized || 0;
            acc[filingwindow].ecfRemaining += processedData.ecfRemaining || 0;
            acc[filingwindow].ecfFRNCountTotal += processedData.ecfFRNCountTotal || 0;
            acc[filingwindow].ecfFRNFundedCountTotal += processedData.ecfFRNFundedCountTotal || 0;
            acc[filingwindow].ecfOriginalRequestTotal += processedData.ecfOriginalRequestTotal || 0;
            acc[filingwindow].ecfSubtotalEquipmentTotal += processedData.ecfSubtotalEquipmentTotal || 0;
            acc[filingwindow].ecfSubtotalServicesTotal += processedData.ecfSubtotalServicesTotal || 0;
            acc[filingwindow].ecfCommittedRequestTotal += processedData.ecfCommittedRequestTotal || 0;
            acc[filingwindow].ecfDisbursedTotal += processedData.ecfDisbursedTotal || 0;
            acc[filingwindow].ecfRemainingTotal += processedData.ecfRemainingTotal || 0;

            return acc;
        }, {});

        // Convert the result back to an array
        return Object.values(groupedData).map((windowData) => ({
            filingwindow: windowData.filingwindow,
            ecfUtilized: windowData.ecfUtilized * 100,
            ecfRemaining: windowData.ecfRemaining,
            ecfFRNCountTotal: windowData.ecfFRNCountTotal,
            ecfFRNFundedCountTotal: windowData.ecfFRNFundedCountTotal,
            ecfOriginalRequestTotal: windowData.ecfOriginalRequestTotal,
            ecfSubtotalEquipmentTotal: windowData.ecfSubtotalEquipmentTotal,
            ecfSubtotalServicesTotal: windowData.ecfSubtotalServicesTotal,
            ecfCommittedRequestTotal: windowData.ecfCommittedRequestTotal,
            ecfDisbursedTotal: windowData.ecfDisbursedTotal,
            ecfRemainingTotal: windowData.ecfRemainingTotal,
        }));
    };

    const calculateECFTotals = (processedData) => {
        let acc = {
            ecfUtilized: 0,
            ecfRemaining: 0,
            ecfFRNCountTotal: 0,
            ecfFRNFundedCountTotal: 0,
            ecfOriginalRequestTotal: 0,
            ecfSubtotalEquipmentTotal: 0,
            ecfSubtotalServicesTotal: 0,
            ecfCommittedRequestTotal: 0,
            ecfDisbursedTotal: 0,
            ecfRemainingTotal: 0,
        };

        processedData.forEach((ea_obj) => {
            // Accumulate monetary values as numbers
            acc.ecfRemaining += parseFloat(ea_obj.ecfRemaining) || 0;
            acc.ecfOriginalRequestTotal += parseFloat(ea_obj.ecfOriginalRequestTotal) || 0;
            acc.ecfSubtotalEquipmentTotal += parseFloat(ea_obj.ecfSubtotalEquipmentTotal) || 0;
            acc.ecfSubtotalServicesTotal += parseFloat(ea_obj.ecfSubtotalServicesTotal) || 0;
            acc.ecfCommittedRequestTotal += parseFloat(ea_obj.ecfCommittedRequestTotal) || 0;
            acc.ecfDisbursedTotal += parseFloat(ea_obj.ecfDisbursedTotal) || 0;
            acc.ecfRemainingTotal += parseFloat(ea_obj.ecfRemainingTotal) || 0;

            // Accumulate the "count" columns
            acc.ecfFRNCountTotal += ea_obj.ecfFRNCountTotal || 0;
            acc.ecfFRNFundedCountTotal += ea_obj.ecfFRNFundedCountTotal || 0;

            // Calculating the total utilization percentage
            if (ea_obj.ecfCommittedRequestTotal > 0) {
                const utilization = ea_obj.ecfDisbursedTotal / ea_obj.ecfCommittedRequestTotal;
                acc.ecfUtilized += utilization;
            }
        });

        // Calculating the total average utilization percentage
        acc.ecfUtilized = (acc.ecfDisbursedTotal / acc.ecfCommittedRequestTotal) * 100;

        return acc;
    };

    const shouldDisplayAsLink = (rowData) => {
        const relevantFields = [
            rowData.ecfOriginalRequestTotal,
            rowData.ecfSubtotalEquipmentTotal,
            rowData.ecfSubtotalServicesTotal,
            rowData.ecfCommittedRequestTotal,
            rowData.ecfDisbursedTotal,
            rowData.ecfRemainingTotal,
            parseFloat(rowData.ecfUtilized),
        ];

        // If any value in the fields is not 0, return true (to display as a link)
        return relevantFields.some((value) => {
            const parsedValue = typeof value === 'string' ? parseFloat(value.replace(/[$,]/g, '')) : value;
            return parsedValue && parsedValue !== 0;
        });
    };

    // Used for E-Rate Organizer Utilization Summary Chart's totals column
    const ecfTotals = calculateECFTotals(newClientECFData);
    const frnTotals = clientFRNData['totals'];
    const totalYears = Object.keys(clientFRNData).length - 1;

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
                <h3 style={{ margin: 0, marginRight: 4 }}>{`Report:`}</h3>
                <FormControl sx={{ minWidth: 100 }}>
                    <Select
                        id='funding-year-selection'
                        label='Funding Year Selection'
                        value={selectedYear ? selectedYear : 'All Years Summary'}
                        onChange={handleFundingYearSelectionChange}
                        sx={{ height: '2.2rem', fontSize: '0.875rem' }}
                    >
                        {years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <h4 style={{ color: '#19638B', marginTop: '10px', marginBottom: '10px' }}>
                E-Rate Utilization Summary Chart
            </h4>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} size='small'>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                            {frnHeaders.map((header) => (
                                <TableCell
                                    key={header.id}
                                    sortDirection={frnOrderBy === header.id ? frnOrder : false}
                                    sx={{
                                        padding: '4px 8px',
                                        textAlign: [
                                            'amtOrigReq',
                                            'amtPriority1',
                                            'amtPriority2',
                                            'amtComm',
                                            'amtDisb',
                                            'amtBal',
                                            'pcUtil',
                                        ].includes(header.id)
                                            ? 'right'
                                            : 'left', // Right-align financial columns
                                        paddingRight:
                                            header.id === 'pcUtil'
                                                ? '0px'
                                                : [
                                                      'amtOrigReq',
                                                      'amtPriority1',
                                                      'amtPriority2',
                                                      'amtComm',
                                                      'amtDisb',
                                                      'amtBal',
                                                      'pcUtil',
                                                  ].includes(header.id)
                                                ? '2%'
                                                : '1%',
                                        paddingLeft:
                                            header.id === 'pcUtil'
                                                ? '16px'
                                                : ['numReq', 'numFunded', 'num486'].includes(header.id)
                                                ? '2.5%'
                                                : undefined,
                                        whiteSpace: 'nowrap', // Prevent text wrapping for headers
                                        width: [
                                            'amtOrigReq',
                                            'amtPriority1',
                                            'amtPriority2',
                                            'amtComm',
                                            'amtDisb',
                                            'amtBal',
                                        ].includes(header.id)
                                            ? '10%'
                                            : 'auto', // Allocate width as a percentage for financial columns
                                    }}
                                >
                                    <TableSortLabel
                                        active={frnOrderBy === header.id}
                                        direction={frnOrderBy === header.id ? frnOrder : 'asc'}
                                        onClick={() => handleFRNRequestSort(header.id)}
                                    >
                                        {header.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedFRNData.map((yearData, index) => (
                            <TableRow
                                key={`${yearData.year}-${index}`}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                                }}
                            >
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    <Link
                                        href={
                                            spin
                                                ? `/client-list/funding-year-details/${clientId}/${spin}/${yearData.year}/${searchType}`
                                                : `/client-list/funding-year-details/${clientId}/${kyList}/${yearData.year}/${searchType}`
                                        }
                                        rel='noreferrer'
                                        sx={{ textDecoration: 'none' }}
                                    >
                                        {yearData.year}
                                    </Link>
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                    {yearData.numReq}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                    {yearData.numFunded}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                    {yearData.num486}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                    {yearData.amtOrigReq}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                    {yearData.amtPriority1}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                    {yearData.amtPriority2}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                    {yearData.amtComm}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                    {yearData.amtDisb}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                    {yearData.amtBal}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '1%' }}>
                                    {isNaN(yearData.pcUtil) ? '0.00%' : `${yearData.pcUtil.toFixed(2)}%`}
                                </TableCell>
                            </TableRow>
                        ))}

                        <TableRow sx={{ backgroundColor: sortedFRNData.length % 2 === 0 ? 'white' : '#f5f5f5' }}>
                            <TableCell sx={{ padding: '4px 8px' }}>Totals</TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                {frnTotals.numReq}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                {frnTotals.numFunded}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                {frnTotals.num486}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                {formatMoney(frnTotals.amtOrigReq)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                {formatMoney(frnTotals.amtPriority1)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                {formatMoney(frnTotals.amtPriority2)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                {formatMoney(frnTotals.amtComm)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                {formatMoney(frnTotals.amtDisb)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '3%' }}>
                                {formatMoney(frnTotals.amtBal)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '1%' }}>
                                {isNaN(frnTotals.pcUtil / totalYears)
                                    ? '0.00%'
                                    : `${(frnTotals.pcUtil / totalYears).toFixed(2)}%`}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Emergency Connectivity Fund Utilization Summary Chart */}
            <h4 style={{ color: '#19638B', marginTop: '50px', marginBottom: '10px' }}>
                Emergency Connectivity Fund Utilization Summary Chart
            </h4>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} size='small'>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                            {ecfHeaders.map((header) => (
                                <TableCell
                                    key={header.id}
                                    sortDirection={ecfOrderBy === header.id ? ecfOrder : false}
                                    sx={{
                                        padding: '4px 8px',
                                        textAlign: [
                                            'ecfFRNCountTotal',
                                            'ecfFRNFundedCountTotal',
                                            'ecfOriginalRequestTotal',
                                            'ecfSubtotalEquipmentTotal',
                                            'ecfSubtotalServicesTotal',
                                            'ecfCommittedRequestTotal',
                                            'ecfDisbursedTotal',
                                            'ecfRemainingTotal',
                                            'ecfUtilized',
                                        ].includes(header.id)
                                            ? 'right'
                                            : 'left',
                                        paddingRight: [
                                            'ecfOriginalRequestTotal',
                                            'ecfSubtotalEquipmentTotal',
                                            'ecfSubtotalServicesTotal',
                                            'ecfCommittedRequestTotal',
                                            'ecfDisbursedTotal',
                                            'ecfRemainingTotal',
                                            'ecfUtilized',
                                        ].includes(header.id)
                                            ? '0px'
                                            : '5px',
                                        paddingLeft: header.id === 'ecfUtilized' ? '20px' : undefined,
                                        whiteSpace: 'nowrap',
                                        width: [
                                            'ecfOriginalRequestTotal',
                                            'ecfSubtotalEquipmentTotal',
                                            'ecfSubtotalServicesTotal',
                                            'ecfCommittedRequestTotal',
                                            'ecfDisbursedTotal',
                                            'ecfRemainingTotal',
                                            // 'ecfUtilized',
                                        ].includes(header.id)
                                            ? '10%'
                                            : 'auto',
                                    }}
                                >
                                    <TableSortLabel
                                        active={ecfOrderBy === header.id}
                                        direction={ecfOrderBy === header.id ? ecfOrder : 'asc'}
                                        onClick={() => handleECFRequestSort(header.id)}
                                    >
                                        {header.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedECFData.map((rowData, index) => (
                            <TableRow
                                key={`${rowData.filingwindow}-${index}`}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                                }}
                            >
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {shouldDisplayAsLink(rowData) ? (
                                        <Link
                                            href={`/client-list/funding-history-details/${clientId}/${
                                                spin ? spin : kyList
                                            }/ECF/${searchType}`}
                                            rel='noreferrer'
                                            sx={{
                                                textDecoration: 'none',
                                                fontWeight: 'normal',
                                                fontSize: '.9rem',
                                            }}
                                        >
                                            {rowData.filingwindow}
                                        </Link>
                                    ) : (
                                        rowData.filingwindow
                                    )}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                    {rowData.ecfFRNCountTotal}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                    {rowData.ecfFRNFundedCountTotal}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                    {formatMoney(rowData.ecfOriginalRequestTotal)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                    {formatMoney(rowData.ecfSubtotalEquipmentTotal)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                    {formatMoney(rowData.ecfSubtotalServicesTotal)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                    {formatMoney(rowData.ecfCommittedRequestTotal)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                    {formatMoney(rowData.ecfDisbursedTotal)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                    {formatMoney(rowData.ecfRemainingTotal)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                    {isNaN(rowData.ecfUtilized) ? '0.00%' : `${rowData.ecfUtilized.toFixed(2)}%`}
                                </TableCell>
                            </TableRow>
                        ))}

                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                            <TableCell sx={{ padding: '4px 8px' }}>Totals</TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                {ecfTotals.ecfFRNCountTotal}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                {ecfTotals.ecfFRNFundedCountTotal}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                {formatMoney(ecfTotals.ecfOriginalRequestTotal)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                {formatMoney(ecfTotals.ecfSubtotalEquipmentTotal)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                {formatMoney(ecfTotals.ecfSubtotalServicesTotal)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                {formatMoney(ecfTotals.ecfCommittedRequestTotal)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                {formatMoney(ecfTotals.ecfDisbursedTotal)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                {formatMoney(ecfTotals.ecfRemainingTotal)}
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', textAlign: 'right', paddingRight: '25px' }}>
                                {isNaN(ecfTotals.ecfUtilized) ? '0.00%' : `${ecfTotals.ecfUtilized.toFixed(2)}%`}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant='body2' sx={{ marginTop: 5, fontSize: '0.875rem' }}>
                <strong>Service Legend:</strong> Category 1 includes Telecomm, Voice, Data Transmission and/or Internet
                Access; Category 2 includes Internal Connections, Internal Connections Maintenance and Managed Internal
                Broadband Services
            </Typography>
        </Box>
    );
}
