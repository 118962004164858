import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';
import { addDays, subDays } from 'date-fns';
import PropTypes from 'prop-types';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    DataGridPro,
    useGridApiRef,
    GridRowModes,
    GridActionsCellItem,
    gridColumnFieldsSelector,
} from '@mui/x-data-grid-pro';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ArticleIcon from '@mui/icons-material/Article';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MultiAutoComplete from './MultiAutoComplete.js';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Dialog, DialogContent, DialogContentText, TextField, DialogActions, Tooltip } from '@mui/material';

import { API } from 'aws-amplify';
import ClientAPI from '../api/ClientAPI.js';
// import TrackersAPI from '../api/TrackersAPI.js';
import './CustomDatagridToolbarARCL.js';
import CustomDatagridToolbarARCL from './CustomDatagridToolbarARCL.js';
import removeAnyBooleanFilterValueWrapper from './customcomponents/RemoveAnyBooleanFilterValue.js';
import doesNotContainWrapper from './customcomponents/DoesNotContainFilter.js';
import IsEqualToColumnWrapper from './customcomponents/IsEqualToColumnFilter.js';
import IsNotEqualToColumnWrapper from './customcomponents/IsNotEqualToColumnFilter.js';
import IsGreaterThanColumnWrapper from './customcomponents/IsGreaterThanColumnFilter.js';
import IsGreaterThanOrEqualToColumnWrapper from './customcomponents/IsGreaterThanOrEqualToColumnFilter.js';
import IsLessThanColumnWrapper from './customcomponents/IsLessThanColumnFilter.js';
import IsLessThanOrEqualToColumnWrapper from './customcomponents/IsLessThanOrEqualToColumnFilter.js';
import IsAnyOfWrapper from './customcomponents/IsAnyOfFilter.js';
import IsNotAnyOfWrapper from './customcomponents/IsNotAnyOfFilter.js';
// import IsBeforeXDaysWrapper from './customcomponents/IsBeforeXDaysFilter.js';
// import IsAfterXDaysWrapper from './customcomponents/IsAfterXDaysFilter.js';
import { NotesCellDisplay } from './customcomponents/RenderCellExpandV2.js'; // NEW
import { InlineEditTextarea1 } from './customcomponents/InlineEditTextarea.js';
import { InlineEditDropdown } from './customcomponents/InlineEditDropdown.js';
import { useAuthContext } from '../useAuthContext';
import NotesEditDialog from './dialogs/NotesEditDialog.js';
import { PromptUpdateOrSaveNewViewDialog } from './customcomponents/PromptUpdateOrSaveNewViewDialog.js';
import { PromptUpdateOrSaveNewSearchDialog } from './customcomponents/PromptUpdateOrSaveNewSearchDialog.js';

const appliesTo = 'Client List';
const defaultFilter = '{"items":[]}';
const defaultSort = '[]';
const defaultHiddenColumns =
    '{"filingForECF1":false,"filingForECF2":false,"filingForECF3":false,"is_business_org":false,"legacy_primary_ben":false,"legacy_clientid":false}';
const defaultPinnedColumns = '{"left":["actions"],"right":[]}';

const CACHE_INCREMENT = 1;
const LS_INCREMENT = 2;
const CLIENT_CACHE_NAME = 'ced-client-cache-' + CACHE_INCREMENT;
const CLIENT_FILTER_LS_NAME = 'cedFilterPrefs' + LS_INCREMENT;
const CLIENT_SORT_LS_NAME = 'cedSortPrefs' + LS_INCREMENT;
const CLIENT_HIDDEN_LS_NAME = 'cedHiddenColumns' + LS_INCREMENT;
const CLIENT_PINNED_LS_NAME = 'cedPinnedColumns' + LS_INCREMENT;
const CLIENT_ORDER_LS_NAME = 'cedColumnOrder' + LS_INCREMENT;
const CLIENT_ROWHEIGHT_LS_NAME = 'cedRowHeight' + LS_INCREMENT;
const CLIENT_DENSITY_LS_NAME = 'cedClientListDensityPref' + LS_INCREMENT;

function DetailPanelContent({ row: rowProp }) {
    const clientAPI = new ClientAPI();
    // const trackersAPI = new TrackersAPI();
    // const { appCount, setAppCount } = useAppCountContext();

    const [activeClientServices, setActiveClientServices] = React.useState([]);
    const [fullSvcClientServices, setFullSvcClientServices] = React.useState([]);
    const [reviewClientServices, setReviewClientServices] = React.useState([]);
    const [trackClientServices, setTrackClientServices] = React.useState([]);
    const [noneClientServices, setNoneClientServices] = React.useState([]);
    const [fundingYears, setFundingYears] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);
    const currentMonth = new Date().getMonth(); // Zero-based
    const currentYear = new Date().getFullYear();

    React.useEffect(() => {
        const fetchClientServicesForClient = async () => {
            try {
                let currDate = new Date();
                let allClientServices = await clientAPI.GetClientServices(rowProp.client_id);

                let currentClientServices = allClientServices.filter(
                    (cs) =>
                        Date.parse(cs.start_date) <= currDate && (!cs.end_date || Date.parse(cs.end_date) >= currDate)
                );

                let fullSvc = currentClientServices
                    .filter((cs) => cs.service_action.service_text === 'Full Svc')
                    .sort((a, b) => a.service.service_grouping - b.service.service_grouping);
                let review = currentClientServices
                    .filter((cs) => cs.service_action.service_text === 'Review')
                    .sort((a, b) => a.service.service_grouping - b.service.service_grouping);
                let track = currentClientServices
                    .filter((cs) => cs.service_action.service_text === 'Track')
                    .sort((a, b) => a.service.service_grouping - b.service.service_grouping);
                let none = currentClientServices
                    .filter((cs) => cs.service_action.service_text === 'None')
                    .sort((a, b) => a.service.service_grouping - b.service.service_grouping);

                //console.log('ClientListTableWithSearch: currentClientServices = ', currentClientServices);
                setActiveClientServices(currentClientServices);
                setFullSvcClientServices(fullSvc);
                setReviewClientServices(review);
                setTrackClientServices(track);
                setNoneClientServices(none);
                setLoaded(true);
            } catch (error) {
                console.error(error);
            }
        };
        fetchClientServicesForClient();
    }, []);

    React.useEffect(() => {
        const years = [];

        for (let year = 2016; year <= currentYear + 1; year++) {
            if (year === currentYear + 1 && currentMonth < 6) {
                continue;
            }
            years.push(year);
        }

        years.sort((a, b) => b - a);
        setFundingYears(years);
    }, [currentMonth, currentYear]);

    if (activeClientServices.length === 0 && !loaded) {
        return <></>;
    } else if (activeClientServices.length === 0 && loaded) {
        return (
            <Stack sx={{ marginLeft: '75px', marginTop: '25px', marginBottom: '25px' }}>
                <Typography variant='h3'>No active services for client</Typography>
            </Stack>
        );
    } else {
        return (
            <Stack sx={{ marginLeft: '75px', marginTop: '25px', marginBottom: '25px' }}>
                <Box sx={{ display: 'flex', marginTop: '25px', marginBottom: '25px' }}>
                    <TableContainer component={Paper} sx={{ width: '25%' }}>
                        <Table sx={{ fontSize: '0.875rem' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'center' }}>FY</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>470 Apps</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>471 Apps</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fundingYears.map((year, i) => (
                                    <TableRow key={`${year}-${i}`}>
                                        <TableCell style={{ textAlign: 'center' }}>{year}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            <Tooltip title='Open in new tab' placement='top'>
                                                <Link
                                                    href={`/f470/f470-applications?ben=${rowProp.bensList[0]}&fundingYear=${year}`}
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    {'Form 470 Apps'}
                                                </Link>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            <Tooltip title='Open in new tab' placement='top'>
                                                <Link
                                                    href={`/f471/f471-applications?ben=${rowProp.bensList[0]}&fundingYear=${year}`}
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    {'Form 471 Apps'}
                                                </Link>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Typography variant='h3'>Active Services For Client</Typography>
                <ul>
                    {fullSvcClientServices.length > 0 && (
                        <li>
                            <strong>Full Service: </strong>
                            {fullSvcClientServices.map((cs) => {
                                return <>{cs.service.service_name}, </>;
                            })}
                        </li>
                    )}

                    {reviewClientServices.length > 0 && (
                        <li>
                            <strong>Review: </strong>
                            {reviewClientServices.map((cs) => {
                                return <>{cs.service.service_name}, </>;
                            })}
                        </li>
                    )}

                    {trackClientServices.length > 0 && (
                        <li>
                            <strong>Track: </strong>
                            {trackClientServices.map((cs) => {
                                return <>{cs.service.service_name}, </>;
                            })}
                        </li>
                    )}

                    {noneClientServices.length > 0 && (
                        <li>
                            <strong>None: </strong>
                            {noneClientServices.map((cs) => {
                                return <>{cs.service.service_name}, </>;
                            })}
                        </li>
                    )}
                </ul>
            </Stack>
        );
    }
}

DetailPanelContent.propTypes = {
    row: PropTypes.object.isRequired,
};

function ClientListTableWithSearch(props) {
    const clientAPI = props.clientAPI;
    const userAPI = props.userAPI;
    const handleDetailsButtonClick = props.handleDetailsButtonClick;
    const handleFundingSummaryButtonClick = props.handleFundingSummaryButtonClick;
    const handleEditButtonClick = props.handleEditButtonClick;
    const handleCreateButtonClick = props.handleCreateButtonClick;

    const [clientList, setClientList] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(2000);
    const [densityPref, setDensityPref] = useLocalStorage(CLIENT_DENSITY_LS_NAME, 'standard');
    const [filterPrefs, setFilterPrefs] = useLocalStorage(CLIENT_FILTER_LS_NAME, defaultFilter);
    const [sortPrefs, setSortPrefs] = useLocalStorage(CLIENT_SORT_LS_NAME, defaultSort);
    const [hiddenColumnPrefs, setHiddenColumnPrefs] = useLocalStorage(CLIENT_HIDDEN_LS_NAME, defaultHiddenColumns);
    const [pinnedColumnPrefs, setPinnedColumnPrefs] = useLocalStorage(CLIENT_PINNED_LS_NAME, defaultPinnedColumns);
    const [columnResetCount, setColumnResetCount] = React.useState(0);
    const FIXED_ROW_HEIGHT = 38;
    const [rowHeightPref, setRowHeightPref] = useLocalStorage(CLIENT_ROWHEIGHT_LS_NAME, 'fixed');

    const [primaryContactEditOptions, setPrimaryContactEditOptions] = React.useState([]);
    const [editDropdownOptionsRendered, setEditDropdownOptionsRendered] = React.useState(false);
    const [usedCacheOnLoad, setUsedCacheOnLoad] = React.useState(false);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const apiRef = useGridApiRef();
    const { state } = useLocation();
    const { updatedRow } = state || {};

    const [searchParameters, setSearchParameters] = React.useState(null);
    const [currentUserID, setCurrentUserID] = React.useState(null);
    const [clientData, setClientData] = React.useState([]);
    const [dataIsLoading, setDataIsLoading] = React.useState(false);
    let params = useParams();

    // ################################### VIEW STATE #############################################
    const auth = useAuthContext();
    const [userID, setUserID] = React.useState('');
    const [currentSavedViews, setCurrentSavedViews] = React.useState([]);
    const [selectedViewName, setSelectedViewName] = React.useState('');
    const [editingViewName, setEditingViewName] = React.useState('');
    const [currentColumnOrder, setCurrentColumnOrder] = React.useState([]);
    const [currentColumnWidths, setCurrentColumnWidths] = React.useState({});
    const [isDialogOpen1, setIsDialogOpen1] = React.useState(false);
    const [isDialogOpen2, setIsDialogOpen2] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [landing, setLanding] = React.useState(true);
    const [showTextField1, setShowTextField1] = React.useState(false);
    const [showTextField2, setShowTextField2] = React.useState(true);
    const [textFieldValue, setTextFieldValue] = React.useState('');

    // ################################### NOTES STATE #############################################
    const [openNotesEditDialog, setOpenNotesEditDialog] = React.useState(false);
    const [notesEditDialogTitle, setNotesEditDialogTitle] = React.useState('');
    const [notesEditDialogLabel, setNotesEditDialogLabel] = React.useState('');
    const [notesEditRowId, setNotesEditRowId] = React.useState(null);
    const [editableNotes, setEditableNotes] = React.useState('');
    const [notesFieldName, setNotesFieldName] = React.useState('');
    const [userData, setUserData] = React.useState(null);

    // ################################### FILTER OPTIONS #############################################
    const [nameMSFilterOptions, setNameMSFilterOptions] = React.useState(false);
    const [applicantCohortMSFilterOptions, setApplicantCohortMSFilterOptions] = React.useState([]);
    const [stateMSFilterOptions, setStateMSFilterOptions] = React.useState([]);
    const [primaryContactMSFilterOptions, setPrimaryContactMSFilterOptions] = React.useState([]);
    const [secondaryContactMSFilterOptions, setSecondaryContactMSFilterOptions] = React.useState([]);
    const [cipaComplianceMSFilterOptions, setCipaComplianceMSFilterOptions] = React.useState([]);
    const [cyberSecurityPilotParticipationOptions, setCyberSecurityPilotParticipationOptions] = React.useState([]);

    // ################################### VIEW STORAGE NAMES #############################################
    const LS_INCREMENT = 1;
    const SS_INCREMENT = 1;
    const CLIENT_LIST_VIEW_LS_NAME = 'cedCLViewPrefsLS' + LS_INCREMENT;
    const CLIENT_LIST_VIEW_SS_NAME = 'cedCLViewPrefsSS' + SS_INCREMENT;
    const CLIENT_LIST_CURRENT_COL_WIDTHS_LS_NAME = 'cedCLViewColumnCurrentWidthPrefsLS' + LS_INCREMENT;
    const CLIENT_LIST_CURRENT_COL_WIDTHS_SS_NAME = 'cedCLViewColumnCurrentWidthPrefsSS' + SS_INCREMENT;
    const CLIENT_LIST_ORIGINAL_COL_WIDTHS_LS_NAME = 'cedCLViewColumnOriginalWidthPrefsLS' + LS_INCREMENT;
    const CLIENT_LIST_ORIGINAL_COL_WIDTHS_SS_NAME = 'cedCLViewColumnOriginalWidthPrefsSS' + SS_INCREMENT;
    const CLIENT_LIST_CURRENT_COL_ORDER_LS_NAME = 'cedCLViewColumnCurrentOrderPrefsLS' + LS_INCREMENT;
    const CLIENT_LIST_CURRENT_COL_ORDER_SS_NAME = 'cedCLViewColumnCurrentOrderPrefsSS' + SS_INCREMENT;
    const CLIENT_LIST_ORIGINAL_COL_ORDER_LS_NAME = 'cedCLViewColumnOriginalOrderPrefsLS' + LS_INCREMENT;
    const CLIENT_LIST_ORIGINAL_COL_ORDER_SS_NAME = 'cedCLViewColumnOriginalOrderPrefsSS' + SS_INCREMENT;

    // ################################### VIEW LS STORAGE SETTERS #############################################
    const [currentColumnWidthPrefsLS, setCurrentColumnWidthPrefsLS] = useLocalStorage(
        CLIENT_LIST_CURRENT_COL_WIDTHS_LS_NAME,
        ''
    );
    const [originalColumnWidthPrefsLS, setOriginalColumnWidthPrefsLS] = useLocalStorage(
        CLIENT_LIST_ORIGINAL_COL_WIDTHS_LS_NAME,
        ''
    );

    const [currentColumnOrderPrefsLS, setCurrentColumnOrderPrefsLS] = useLocalStorage(
        CLIENT_LIST_CURRENT_COL_ORDER_LS_NAME,
        ''
    );
    const [originalColumnOrderPrefsLS, setOriginalColumnOrderPrefsLS] = useLocalStorage(
        CLIENT_LIST_ORIGINAL_COL_ORDER_LS_NAME,
        ''
    );
    const [viewPrefsLS, setViewPrefsLS] = useLocalStorage(CLIENT_LIST_VIEW_LS_NAME, '');
    // ################################### CUSTOM FILTERS #############################################

    // Setting the original width of columns should only happen once
    React.useEffect(() => {
        const originalOrder = originalColumns.map((ea_col) => ea_col.field);
        const originalWidths = {};
        originalColumns.forEach((ea_col) => {
            originalWidths[ea_col.field] = ea_col.width;
        });

        setOriginalColumnOrderToStorage(JSON.stringify(originalOrder));
        setOriginalColumnWidthsToStorage(JSON.stringify(originalWidths));

        setCurrentColumnOrder(JSON.parse(getCurrentColumnOrderFromStorage()) ?? []);
        setCurrentColumnWidths(JSON.parse(getCurrentColumnWidthsFromStorage()) ?? {});
    }, []);

    const determineInitialShowHideECFColumnsButtonText = () => {
        let hcprefs = JSON.parse(hiddenColumnPrefs);
        if (
            hcprefs['filingForECF1'] === false ||
            hcprefs['filingForECF2'] === false ||
            hcprefs['filingForECF3'] === false
        ) {
            // If any of the ECF columns are hidden (not visible) set the button text to 'Show ECF Columns'.
            return 'Show ECF Columns';
        } else {
            return 'Hide ECF Columns';
        }
    };
    const [showHideECFColumnsButtonText, setShowHideECFColumnsButtonText] = React.useState(
        determineInitialShowHideECFColumnsButtonText
    );

    const determineInitialAutoFixedColumnsButtonText = () => {
        if (rowHeightPref === 'fixed') {
            return 'Auto Row Height';
        } else {
            return 'Fixed Row Height';
        }
    };
    const [autoFixedColumnsButtonText, setAutoFixedColumnsButtonText] = React.useState(
        determineInitialAutoFixedColumnsButtonText
    );

    const getCurrentUserID = async (cognitoID) => {
        const apiName = 'ERateCentralPortalAPI';
        const path = '/GetDatabaseIDForUser';
        const queryStringParameters = { queryStringParameters: { cognito_id: cognitoID } };

        const idResponse = await API.get(apiName, path, queryStringParameters);
        return idResponse;
    };

    // Attempts to pull from SS then LS
    const retrieveViewFieldValuesJSONFromStorage = () => {
        let viewFieldValuesInSS = sessionStorage.getItem(CLIENT_LIST_VIEW_SS_NAME);
        if (viewFieldValuesInSS) {
            return viewFieldValuesInSS;
        }

        if (viewPrefsLS) {
            return viewPrefsLS;
        }
        return null;
    };

    // Gets all of the saved views (should be called on page load within useEffect)
    const getSavedViews = async (ap) => {
        const viewResults = await userAPI.GetSavedViews(ap);
        const tempUserID = await getCurrentUserID(auth.cognitoID);
        const parsedViewResults = JSON.parse(viewResults);

        setUserID(tempUserID);
        setCurrentUserID(tempUserID);

        // Defines the base layout for all retrieved views
        const structuredViews = parsedViewResults.map((ea_row) => ({
            view_id: ea_row.id,
            view_name: ea_row.view_name,
            parameters: ea_row,
        }));
        structuredViews.sort((a, b) => a.view_name.localeCompare(b.view_name));
        console.log('ClientListTableWithSearch: getSavedViewsFN: structuredViews = ', structuredViews);

        setCurrentSavedViews(structuredViews);
        const vnFromStorage = retrieveViewFieldValuesJSONFromStorage();
        if (vnFromStorage) {
            const vnFromStorageParsed = JSON.parse(vnFromStorage);
            setSelectedViewName(vnFromStorageParsed.viewName);
        }
    };

    React.useEffect(() => {
        const checkCacheForPreviousSearch = async () => {
            let APICache = await caches.open(CLIENT_CACHE_NAME); // 'ced-client-cache-' + CACHE_INCREMENT;
            const response = await APICache.match('/ClientList/Search/app/search');
            //console.log('ClientListTableWithSearch: response = ', response);
            if (response !== undefined) {
                const cachedData = await response.json();
                // console.log('ClientListTableWithSearch: cachedData = ', cachedData);
                setClientData(cachedData);
                setUsedCacheOnLoad(true);
            }
        };

        const getClients = async () => {
            let clients = await clientAPI.GetClients();
            setUsedCacheOnLoad(false);
            setClientList(clients);
        };

        // Potential - Filter
        const getOptionsForFiltering = async () => {
            try {
                // Get dropdown and multi-select options
                // Returns a data-populated object of drop-down options, then sets the corresponding states with it
                let options = await clientAPI.ClientListGenerateFilteringOptions();
                console.log('[ClientListTableWithSearch][getOptionsForFiltering] options:', options);
                setNameMSFilterOptions(options.names);
                setApplicantCohortMSFilterOptions(options.applicantCohorts);
                setStateMSFilterOptions(options.states);
                setPrimaryContactMSFilterOptions(options.employees);
                setSecondaryContactMSFilterOptions(options.employees);
                setCipaComplianceMSFilterOptions(options.cipa);
                setCyberSecurityPilotParticipationOptions(options.cyberSecurityPilotParticipation);
            } catch (error) {
                console.log('Error getting search options: ' + error);
                toast.error(error);
            }
        };

        const fetchInlineEditOptions = async () => {
            try {
                let result = await clientAPI.ClientListGenerateFilteringOptions();
                // console.log('result.taskOwners', result.taskOwners);
                setPrimaryContactEditOptions(result.employees);
                setEditDropdownOptionsRendered(true);
            } catch (error) {
                //console.error(error);
                toast.error(error);
            }
        };

        const getUserData = async () => {
            let data = await userAPI.GetUser();
            const parsedObject = JSON.parse(data.body);
            setUserData(parsedObject);
        };

        checkCacheForPreviousSearch();
        getClients();
        fetchInlineEditOptions();
        getOptionsForFiltering();
        getUserData();
        getSavedViews(appliesTo);
        // fetchInlineEditOptions();
    }, []);

    const getDetailPanelContent = React.useCallback(({ row }) => <DetailPanelContent row={row} />, []);

    const getDetailPanelHeight = React.useCallback(() => 'auto', []);

    // ################################### SAVED VIEWS #############################################
    function endsWithView(viewName) {
        const lowercaseName = viewName.trim().toLowerCase();
        return lowercaseName.endsWith('view') || lowercaseName.split(-4).pop().endsWith('view');
    }

    // Check if the view name already exists in the currentSavedViews
    const viewNameExists = (viewName) => {
        return currentSavedViews.some((ea_view) => ea_view.view_name === viewName);
    };

    const handleKeyDownForNewView = (event) => {
        // Stops the select component from navigating while typing
        event.stopPropagation();
        if (event.key === 'Enter') {
            handleOnlySaveAsNewView2();
        }
    };

    // Retrieve the CURRENT width values from session or local storage
    const getCurrentColumnWidthsFromStorage = () => {
        return (
            sessionStorage.getItem(CLIENT_LIST_CURRENT_COL_WIDTHS_SS_NAME) ||
            localStorage.getItem(CLIENT_LIST_CURRENT_COL_WIDTHS_LS_NAME)
        );
    };

    // Set the CURRENT width values to session and local storage
    const setCurrentColumnWidthsToStorage = (params) => {
        sessionStorage.setItem(CLIENT_LIST_CURRENT_COL_WIDTHS_SS_NAME, params);
        setCurrentColumnWidthPrefsLS(params);
    };

    // Retrieve the ORIGINAL width values from session or local storage
    const getOriginalColumnWidthsFromStorage = () => {
        return (
            sessionStorage.getItem(CLIENT_LIST_ORIGINAL_COL_WIDTHS_SS_NAME) ||
            localStorage.getItem(CLIENT_LIST_ORIGINAL_COL_WIDTHS_LS_NAME)
        );
    };

    // Set the ORIGINAL width values to session and local storage
    const setOriginalColumnWidthsToStorage = (params) => {
        sessionStorage.setItem(CLIENT_LIST_ORIGINAL_COL_WIDTHS_SS_NAME, params);
        setOriginalColumnWidthPrefsLS(params);
    };

    // Retrieve the CURRENT order values from session or local storage
    const getCurrentColumnOrderFromStorage = () => {
        return (
            sessionStorage.getItem(CLIENT_LIST_CURRENT_COL_ORDER_SS_NAME) ||
            localStorage.getItem(CLIENT_LIST_CURRENT_COL_ORDER_LS_NAME)
        );
    };

    // Set the CURRENT order values to session and local storage
    const setCurrentColumnOrderToStorage = (params) => {
        sessionStorage.setItem(CLIENT_LIST_CURRENT_COL_ORDER_SS_NAME, params);
        setCurrentColumnOrderPrefsLS(params);
    };

    // Retrieve the ORIGINAL order values from session or local storage
    const getOriginalColumnOrderFromStorage = () => {
        return (
            sessionStorage.getItem(CLIENT_LIST_ORIGINAL_COL_ORDER_SS_NAME) ||
            localStorage.getItem(CLIENT_LIST_ORIGINAL_COL_ORDER_LS_NAME)
        );
    };

    const resetPinnedColumnsPrefs = () => {
        setPinnedColumnPrefs(defaultPinnedColumns);
    };

    // Set the ORIGINAL order values to session and local storage
    const setOriginalColumnOrderToStorage = (params) => {
        sessionStorage.setItem(CLIENT_LIST_ORIGINAL_COL_ORDER_SS_NAME, params);
        setOriginalColumnOrderPrefsLS(params);
    };

    // Saves SFVs to session & local storage
    const saveViewFieldValuesJSONToStorage = (json) => {
        setViewPrefsLS(json);
        sessionStorage.setItem(CLIENT_LIST_VIEW_SS_NAME, json);
    };

    // Function used to reset the view-related data to its defaults (would need a reset to default view button)
    const setDefaultViewValues = () => {
        setFilterPrefs(defaultFilter);
        setDensityPref('standard');
        setHiddenColumnPrefs(defaultHiddenColumns);
        setSortPrefs(defaultSort);
        setViewPrefsLS('');
        sessionStorage.setItem(CLIENT_LIST_VIEW_SS_NAME, '');
    };

    const resetViewToDefaults = () => {
        setSelectedViewName('');
        setTextFieldValue('');
        resetPinnedColumnsPrefs();

        const originalOrder = JSON.parse(getOriginalColumnOrderFromStorage());
        const originalWidth = JSON.parse(getOriginalColumnWidthsFromStorage());

        setCurrentColumnOrder(originalOrder);
        setCurrentColumnOrderToStorage(JSON.stringify(originalOrder));

        setCurrentColumnWidths(originalWidth);
        setCurrentColumnWidthsToStorage(JSON.stringify(originalWidth));

        // Resets the ECF text to default
        setColumnVisibility(['filingForECF1', 'filingForECF2', 'filingForECF3'], false);
        setShowHideECFColumnsButtonText('Show ECF Columns');

        // Resets the Row Height text to default
        setRowHeightPref('fixed');
        setAutoFixedColumnsButtonText('Auto Row Height');
        setDefaultViewValues();
    };

    // Called when a view has been selected via handleSelectSavedViewFN
    const setViewFieldPrefs = (parsedVP) => {
        // Sets the default values for each of view parameters
        setDefaultViewValues();

        // Fill in view values with real data
        try {
            if (parsedVP.viewName) {
                setSelectedViewName(parsedVP.viewName);
            }
            if (parsedVP.filterPrefs != null) {
                setFilterPrefs(parsedVP.filterPrefs);
            }
            if (parsedVP.sortPrefs) {
                setSortPrefs(parsedVP.sortPrefs);
            }
            if (parsedVP.densityPref != null) {
                setDensityPref(parsedVP.densityPref);
            }
            if (parsedVP.hiddenColumnPrefs != null) {
                setHiddenColumnPrefs(parsedVP.hiddenColumnPrefs);
            }
            if (parsedVP.pinnedColumnPrefs != null) {
                setPinnedColumnPrefs(parsedVP.pinnedColumnPrefs);
            }
            if (parsedVP.viewColumnWidthPrefs != null) {
                setCurrentColumnWidthsToStorage(parsedVP.viewColumnWidthPrefs);
                setCurrentColumnWidths(JSON.parse(parsedVP.viewColumnWidthPrefs));
            }
            if (parsedVP.viewColumnOrderPrefs != null) {
                const currentOrder = JSON.parse(parsedVP.viewColumnOrderPrefs);
                setCurrentColumnOrder(currentOrder);
                setCurrentColumnOrderToStorage(parsedVP.viewColumnOrderPrefs);
            }
        } catch (error) {
            console.error('error: ', error);
            toast.error(error);
            return false;
        }
    };

    // Creates an object of key/value pairs of view-related data
    const createViewsParametersObject = () => {
        return {
            selectedViewName: selectedViewName,
            filterPrefs: filterPrefs,
            sortPrefs: sortPrefs,
            densityPref: densityPref,
            hiddenColumnPrefs: hiddenColumnPrefs,
            pinnedColumnPrefs: pinnedColumnPrefs,
            viewColumnWidthPrefs: getCurrentColumnWidthsFromStorage(),
            viewColumnOrderPrefs: getCurrentColumnOrderFromStorage(),
        };
    };

    const handleOnColumnWidthChange = React.useCallback(
        (event) => {
            const allColumns = apiRef.current.getAllColumns();
            const updatedWidths = allColumns.reduce((accumulator, current_col) => {
                accumulator[current_col.field] = current_col.width;
                return accumulator;
            }, {});
            setCurrentColumnWidths(updatedWidths);
        },
        [apiRef]
    );

    React.useEffect(() => {
        setCurrentColumnOrderToStorage(JSON.stringify(currentColumnOrder));
        setCurrentColumnWidthsToStorage(JSON.stringify(currentColumnWidths));
    }, [currentColumnOrder, currentColumnWidths]);

    const saveViewButtonClicked = () => {
        handleSaveView();
    };

    // NOTE: attempting to immediately save the view BEFORE the toast expires can lead to warning being displayed
    const handleSaveView = async () => {
        let newOrUpdatedViewName = selectedViewName;

        if (newOrUpdatedViewName !== '' && newOrUpdatedViewName !== null) {
            // Search for duplicate/determine if the user has already selected a view
            const duplicateViewIndex = currentSavedViews.findIndex(
                (ea_view) => ea_view.view_name === newOrUpdatedViewName
            );

            // If a duplicate was found/the user has already selected a view
            if (duplicateViewIndex !== -1) {
                openDialog1(newOrUpdatedViewName);
            }
        } else {
            openDialog2();
            promptSaveNewView();
        }
        setSelectedViewName(newOrUpdatedViewName);
    };

    const handleOnlyUpdateView = async () => {
        const message = 'update';
        const tempCurrentSavedViews = [...currentSavedViews];

        const duplicateViewIndex = currentSavedViews.findIndex((ea_view) => ea_view.view_name === selectedViewName);
        const matchingSavedView = currentSavedViews[duplicateViewIndex];
        const vp_obj = createViewsParametersObject();

        const replacementView = {
            view_id: matchingSavedView.parameters.id,
            view_name: selectedViewName,
            parameters: vp_obj,
        };
        replacementView['parameters']['viewName'] = selectedViewName;
        tempCurrentSavedViews[duplicateViewIndex] = replacementView;
        vp_obj['viewName'] = selectedViewName;

        const response = await userAPI.SaveView(
            userID,
            message,
            appliesTo,
            tempCurrentSavedViews.at(duplicateViewIndex)
        );

        if (response) {
            setLanding(false);
            setIsDialogOpen1(false);
            saveViewFieldValuesJSONToStorage(JSON.stringify(vp_obj));
            getSavedViews(appliesTo);

            const successMessage = endsWithView(selectedViewName)
                ? `Successfully updated the ${selectedViewName}`
                : `Successfully updated the ${selectedViewName} saved view`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            const failedMessage = endsWithView(selectedViewName)
                ? `Failed to update the ${selectedViewName}`
                : `Failed to update the ${selectedViewName} saved view`;

            toast.error(failedMessage);
        }
        setLanding(true);
    };

    const handleOnlySaveAsNewView1 = async () => {
        setLanding(false);
        setShowTextField1(true);
    };

    const handleOnlySaveAsNewView2 = async () => {
        if (textFieldValue === null) return;
        if (textFieldValue === '') {
            toast.error('Saved view name cannot be empty!');
            return;
        }

        const message = 'new';
        const viewName = textFieldValue.trim();
        const tempCurrentSavedViews = [...currentSavedViews];

        if (viewNameExists(viewName)) {
            toast.error(
                `A saved view with the name ${viewName} already exists. Please try again with a different name.`
            );
            return;
        }

        // Continue with adding the new view
        const vp_obj = createViewsParametersObject();
        const newView = {
            view_name: viewName,
            parameters: vp_obj,
        };
        newView['parameters']['viewName'] = viewName;
        newView['parameters']['selectedViewName'] = viewName; // new view's name
        tempCurrentSavedViews.push(newView);

        const response = await userAPI.SaveView(userID, message, appliesTo, tempCurrentSavedViews.at(-1));
        if (response) {
            setSelectedViewName(viewName);
            saveViewFieldValuesJSONToStorage(JSON.stringify(vp_obj));

            setShowTextField1(false);
            setShowTextField2(false);

            setIsDialogOpen1(false);
            setIsDialogOpen2(false);

            getSavedViews(appliesTo);
            let successMessage = endsWithView(viewName)
                ? `Successfully created the ${viewName}`
                : `Successfully created the ${viewName} saved view`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            let failedMessage = endsWithView(viewName)
                ? `Failed to create the ${viewName}`
                : `Failed to create the ${viewName} saved view`;

            toast.error(failedMessage);
        }
        setLanding(true);
    };

    // Called when the user selects a view from the dropdown
    const handleSelectSavedView = (view_name) => {
        // console.log('view_name is = ', view_name);
        setSelectedViewName(view_name);
        setTextFieldValue(view_name);

        // Finds the matching view object
        const retrieved_view = currentSavedViews.find((ea_view) => ea_view.view_name === view_name);
        // console.log('retrieved_view is = ', retrieved_view);

        // Applies the correct filters to the DGP and sets the filters to storage
        if (retrieved_view) {
            const retrieved_filters = retrieved_view.parameters.view_filters;
            setViewFieldPrefs(retrieved_filters);
            saveViewFieldValuesJSONToStorage(JSON.stringify(retrieved_filters));
        } else {
            return;
        }
    };

    // Called when the user clicks the edit icon
    const handleEditSavedViewName = async (old_name, new_name) => {
        const message = 'update';
        if (!new_name) {
            return;
        }

        // Check if the user entered a new name that's different from the old one
        if (new_name.trim() && new_name !== old_name) {
            // Check if the new name/view_name already exists for the other saved views
            if (currentSavedViews.some((ea_view) => ea_view.view_name === new_name)) {
                alert('A saved view with this name already exists. Please try again with a different name.');
                return;
            }

            // Finding the location of the view we want to edit
            const duplicateViewIndex = currentSavedViews.findIndex((ea_view) => ea_view.view_name === old_name);

            // If a duplicate was found, let's update it
            if (duplicateViewIndex !== -1) {
                // Returns an array of objects with the previous saved view + the edited view
                const updatedViews = currentSavedViews.map((ea_view, index) => {
                    if (index === duplicateViewIndex) {
                        return {
                            ...ea_view,
                            view_name: new_name,
                            parameters: {
                                ...ea_view.parameters,
                                view_name: new_name,
                                view_filters: {
                                    ...ea_view.parameters.view_filters,
                                    viewName: new_name, // for setting selectedViewName on render
                                },
                            },
                        };
                    }
                    return ea_view;
                });

                // Process the edited view
                const view_response = await userAPI.SaveView(
                    userID,
                    message,
                    appliesTo,
                    updatedViews.at(duplicateViewIndex)
                );

                if (old_name === selectedViewName) {
                    setViewPrefsLS(JSON.stringify({ viewName: new_name }));
                    sessionStorage.setItem(CLIENT_LIST_VIEW_SS_NAME, JSON.stringify({ viewName: new_name }));
                }
                getSavedViews(appliesTo);

                if (view_response) {
                    toast.success(`Successfully edited ${old_name} to ${new_name}`, {
                        autoClose: 3000,
                    });
                }
            }
        }
    };

    // Called when the user clicks the delete icon
    const handleDeleteSavedView = async (view_name, view_id, applies_to, user_id) => {
        let confirmationMessage = endsWithView(view_name)
            ? `Are you sure you want to delete the ${view_name}?`
            : `Are you sure you want to delete the ${view_name} saved view?`;
        const confirmation = window.confirm(confirmationMessage);

        if (confirmation) {
            const delete_response = await userAPI.DeleteSavedView(view_id, applies_to, user_id);
            if (delete_response) {
                const successMessage = endsWithView(view_name)
                    ? `Successfully deleted the ${view_name}`
                    : `Successfully deleted the ${view_name} saved view`;

                const emptyViewNameObject = { viewName: '' };
                sessionStorage.setItem(CLIENT_LIST_VIEW_SS_NAME, JSON.stringify(emptyViewNameObject));
                getSavedViews(appliesTo);

                toast.success(successMessage, {
                    autoClose: 3000,
                });
            } else {
                const errorMessage = endsWithView(view_name)
                    ? `Failed to delete the ${view_name}`
                    : `Failed to delete the ${view_name} saved view`;

                toast.error(errorMessage);
            }
        }
    };

    const handleCancelButtonClick1 = () => {
        setShowTextField1(false);
        setIsDialogOpen1(false);
        setLanding(true);
    };

    const handleCancelButtonClick2 = () => {
        setShowTextField2(false);
        setIsDialogOpen2(false);
    };

    const handleKeyDown = (event) => {
        // Stops the select component from navigating while typing
        event.stopPropagation();
        if (event.key === 'Enter') {
            handleOnlySaveAsNewView2();
        }
    };

    const openDialog1 = (newOrUpdatedViewName) => {
        let confirmationMessage = endsWithView(newOrUpdatedViewName)
            ? `Do you want to update the ${newOrUpdatedViewName} or save as a new view?`
            : `Do you want to update the ${newOrUpdatedViewName} saved view or save as a new view?`;
        setDialogMessage(confirmationMessage);
        setIsDialogOpen1(true);
    };

    const openDialog2 = () => {
        setShowTextField2(true);
        setIsDialogOpen2(true);
    };

    const promptSaveNewView = () => {
        return (
            <Dialog open={isDialogOpen2} onClose={() => setIsDialogOpen2(false)}>
                <DialogContent>
                    {showTextField2 && (
                        <Box>
                            {'Please enter a name for the new saved view: '}
                            <TextField
                                autoFocus
                                margin='dense'
                                label='View Name'
                                type='text'
                                fullWidth
                                value={textFieldValue}
                                onChange={(event) => setTextFieldValue(event.target.value)}
                                onKeyDown={handleKeyDownForNewView}
                            />
                            <Box mt={2}>
                                <Button
                                    disabled={textFieldValue === ''}
                                    onClick={handleOnlySaveAsNewView2}
                                    color='primary'
                                >
                                    OK
                                </Button>
                                <Button onClick={handleCancelButtonClick2} color='primary'>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        );
    };
    //################################### END SAVED VIEWS #############################################

    // Along with DG filterModel/onFMChange, narrows down the user-filtered display
    function handleFilterPrefs(newFilterModel) {
        // ===== COMMENTED OUT SERVER SIDE FILTER BEHAVIOR =====
        // let compiled = await trackersAPI.GetAllCompiledData(JSON.stringify(newFilterModel)); // Make API Call on Filter Action (Lambda configured to accept & parse filter model structure)
        // setTrackerData(compiled) // Set State that drives DataGrid
        // ===== END COMMENTED OUT SERVER SIDE FILTER BEHAVIOR =====
        setFilterPrefs(JSON.stringify(newFilterModel));
    }

    function handleOnSortModelChange(newSortModel) {
        setSortPrefs(JSON.stringify(newSortModel));
    }

    function handleOnColumnVisibilityModelChange(newVizModel) {
        const defaultColumns = JSON.parse(defaultHiddenColumns);

        // Needed for the red highlight
        Object.keys(newVizModel).forEach((ea_key) => {
            // Check if the key is not in defaultColumns and its value is true
            if (!defaultColumns.hasOwnProperty(ea_key) && newVizModel[ea_key] === true) {
                delete newVizModel[ea_key];
            }
        });

        setHiddenColumnPrefs(JSON.stringify(newVizModel));
    }

    function handlePinnedPrefs(newPinModel) {
        setPinnedColumnPrefs(JSON.stringify(newPinModel));
    }

    const handleDensityChange = (newDensity) => {
        setDensityPref(newDensity);
    };

    const handleOnColumnOrderChangeV2 = React.useCallback(() => {
        const newColOrder = gridColumnFieldsSelector(apiRef.current.state);
        setCurrentColumnOrder(newColOrder);
        setCurrentColumnOrderToStorage(JSON.stringify(newColOrder));
    }, [apiRef]);

    const setColumnVisibility = (columnsToHide, bVisible) => {
        let existingPrefs = JSON.parse(hiddenColumnPrefs);
        columnsToHide.forEach((colName) => (existingPrefs[colName] = bVisible));
        setHiddenColumnPrefs(JSON.stringify(existingPrefs));
    };

    const showAll = () => {
        setHiddenColumnPrefs('{}');
    };

    // Based on the showHide text value, calls the setColumnVisibility with the list of columns to be set to true/false (each), and inverts the value of showHide afterwards
    const showHideECFColumns = () => {
        if (showHideECFColumnsButtonText === 'Show ECF Columns') {
            setColumnVisibility(['filingForECF1', 'filingForECF2', 'filingForECF3'], true);
            setShowHideECFColumnsButtonText('Hide ECF Columns');
        } else {
            setColumnVisibility(['filingForECF1', 'filingForECF2', 'filingForECF3'], false);
            setShowHideECFColumnsButtonText('Show ECF Columns');
        }
    };

    const toggleRowHeight = () => {
        setRowHeightPref(rowHeightPref === 'fixed' ? 'auto' : 'fixed');
    };

    const autoFixedRowHeight = () => {
        if (autoFixedColumnsButtonText === 'Auto Row Height') {
            toggleRowHeight();
            setAutoFixedColumnsButtonText('Fixed Row Height');
        } else {
            toggleRowHeight();
            setAutoFixedColumnsButtonText('Auto Row Height');
        }
    };

    const getRowHeight = React.useCallback(
        ({ densityFactor }) => {
            //console.log('ClientListTableWithSearch: densityFactor = ' + densityFactor);
            if (rowHeightPref === 'auto') {
                return 'auto';
            } else {
                return FIXED_ROW_HEIGHT * densityFactor; // 0.7, 1, or 1.3
            }
        },
        [rowHeightPref]
    );

    const resetColumnOrderToDefault = () => {
        resetPinnedColumnsPrefs();

        const originalColumnOrder = getOriginalColumnOrderFromStorage();
        setCurrentColumnOrder(JSON.parse(originalColumnOrder));
        setCurrentColumnOrderToStorage(originalColumnOrder);
        setColumnResetCount((prevCount) => prevCount + 1);
    };

    const resetColumnWidthToDefault = () => {
        const originalColumnWidth = getOriginalColumnWidthsFromStorage();
        setCurrentColumnWidths(JSON.parse(originalColumnWidth));
        setCurrentColumnWidthsToStorage(originalColumnWidth);
    };

    // readjust column widths here
    const resetColumnVisibilityToDefault = () => {
        resetColumnWidthToDefault();
        setHiddenColumnPrefs(defaultHiddenColumns);
        if (showHideECFColumnsButtonText === 'Hide ECF Columns') {
            showHideECFColumns();
        }
    };

    const resetFiltersToDefault = () => {
        setFilterPrefs(defaultFilter);
    };

    //----- Generate the report (whenever searchParameters changes) -----
    React.useEffect(() => {
        const searchClients = async (searchParameters) => {
            try {
                if (!usedCacheOnLoad) {
                    setDataIsLoading(true);
                }

                // const services = searchParameters.services.map(service => service.toString());

                // Contains LS/SS data or default data
                // Potential
                const searchParametersForAPI = {
                    ben: searchParameters.ben,
                    name: searchParameters.name,
                    primary_contacts: searchParameters.primaryContacts,
                    secondary_contacts: searchParameters.secondaryContacts,
                    states: searchParameters.states,
                    applicant_cohorts: searchParameters.applicantCohorts,
                    is_a_client: searchParameters.isAClient,
                    cipa_compliance: searchParameters.cipaCompliance,
                    services: searchParameters.services,
                    teamIDs: searchParameters.teamIDs,
                    client_start_date_from: searchParameters.clientStartDateFrom,
                    client_start_date_to: searchParameters.clientStartDateTo,
                    client_end_date_from: searchParameters.clientEndDateFrom,
                    client_end_date_to: searchParameters.clientEndDateTo,
                    cybersecuritypilot_participation: searchParameters.cyberSecurityPilotParticipation,
                    // deadline_field_name: searchParameters?.deadlineFieldName?.value ?? null,
                    // deadline_field_operator: searchParameters?.deadlineFieldOperator?.value ?? null,
                    // deadline_field_days: searchParameters?.deadlineFieldDays ?? null,
                    // calculated_deadline_date: searchParameters.calculatedDeadlineDate
                };

                // const deadlineFieldName = searchParameters?.deadlineFieldName?.value ?? null;
                // const deadlineFieldOperator = searchParameters?.deadlineFieldOperator?.value ?? null;
                // const deadlineFieldDays = searchParameters?.deadlineFieldDays ?? null;
                // console.log(deadlineFieldName, deadlineFieldOperator, deadlineFieldDays);

                // if (deadlineFieldName && deadlineFieldOperator && deadlineFieldDays) {
                //     let targetDate = 0;
                //     const currentDate = new Date();
                //     searchParametersForAPI.current_date = currentDate.toISOString().split('T')[0];

                //     if (deadlineFieldOperator == 'isBeforeXDays') {
                //         targetDate = subDays(currentDate, deadlineFieldDays).toISOString().split('T')[0];
                //         searchParametersForAPI.calculated_deadline_date = targetDate;
                //     }

                //     if (deadlineFieldOperator == 'isAfterXDays') {
                //         targetDate = addDays(currentDate, deadlineFieldDays).toISOString().split('T')[0];
                //         searchParametersForAPI.calculated_deadline_date = targetDate;
                //     }
                // }
                console.log(
                    '[ClientListTableWithSearch, React.useEffect, searchClients]  searchParametersForAPI = ',
                    searchParametersForAPI
                );

                let clientListResult = await clientAPI.ClientListSearch(searchParametersForAPI);
                console.log(
                    '[ClientListTableWithSearch, React.useEffect, searchClients]  clientListResult = ',
                    clientListResult
                );
                setClientData(clientListResult);

                if (clientListResult !== false && clientListResult.body !== '[]') {
                    let APICache = await caches.open(CLIENT_CACHE_NAME);
                    let headersOptions = { headers: { 'Content-Type': 'application/json' } };
                    APICache.put(
                        '/ClientList/Search/app/search',
                        new Response(JSON.stringify(clientListResult), headersOptions)
                    );
                } else {
                    // console.log('ClientListTableWithSearch: clientListSearchResult.body IS FALSE/UNDEFINED');
                }

                setClientData(clientListResult);
                setUsedCacheOnLoad(false);
                setDataIsLoading(false);
            } catch (error) {
                console.log(error);
                toast.error(error);
            }
        };

        if (searchParameters) {
            console.log('ClientListTableWithSearch: executing search...');
            if (!usedCacheOnLoad) {
                setClientData([]); // Setting data to a empty array displays the loading spinner while we query for new data.
            }
            searchClients(searchParameters);
        }
    }, [searchParameters]);

    // ################################### Custom Notes Start (NotesEditDialog) #############################################

    const usernameForNotesEditDialog = userData ? userData.email.split('@')[0] : '';

    const handleEditNotesEvent = (rowId, fieldName, currentNotes, bens, name) => {
        //console.log(`[handleEditNotesEvent] rowId = ${rowId} | fieldName = ${fieldName} | bens = ${bens} | name = ${name}`);
        setNotesEditRowId(rowId);
        setNotesFieldName(fieldName);
        setNotesEditDialogTitle(`Edit Note for BEN${bens.length > 1 ? 's' : ''}: ${bens}, Name: ${name}`);
        setNotesEditDialogLabel('Notes');
        setEditableNotes(currentNotes ? currentNotes : '');
        setOpenNotesEditDialog(true);
    };

    const handleNotesEditDialogSave = (newNotes) => {
        //console.log('[handleNotesEditDialogSave] ');
        saveNewNotes(newNotes);
        setOpenNotesEditDialog(false);
        cleanupNotesEditDialogStateVars();
    };

    const saveNewNotes = async (newNotes) => {
        // Find the record to edit/update in `clientData`.
        const specificRow = clientData.find((row) => row.client_id === notesEditRowId);
        // console.log('clientData --> ', clientData);
        //console.log('handleSaveNote --> specificRow: ', specificRow);

        // Create `uedata` and call the 'save notes' api endpoint.
        let uedata = {
            client_id: specificRow.client_id,
            notes: newNotes,
        };
        //console.log('handleSaveNote --> uedata: ', uedata);

        const apiResponse = await clientAPI.SaveClientNotes(uedata);
        //console.log('handleSaveNote --> apiResponse: ', apiResponse);

        if (apiResponse) {
            // Update the data locally.
            updateClientData_WithNewNotes(notesEditRowId, apiResponse.notes);

            toast.success('Successfully updated the Notes field', {
                autoClose: 3000,
            });
        } else {
            toast.error('Failed to update the Notes field');
        }
    };

    const updateClientData_WithNewNotes = (rowId, newNotes) => {
        let updatedClientData = clientData.map((row) => {
            if (row.client_id === rowId) {
                return { ...row, notes: newNotes };
            }
            return row;
        });
        setClientData(updatedClientData);
    };

    const handleNotesEditDialogCancel = () => {
        //console.log('[handleNotesEditDialogCancel] ');
        setOpenNotesEditDialog(false);
        cleanupNotesEditDialogStateVars();
    };

    const handleNotesEditDialogClose = () => {
        //console.log('[handleNotesEditDialogClose] ');
        setOpenNotesEditDialog(false);
        cleanupNotesEditDialogStateVars();
    };

    const cleanupNotesEditDialogStateVars = () => {
        setNotesEditRowId(null);
        setNotesFieldName('');
        setNotesEditDialogLabel('');
        setNotesEditDialogTitle('');
        setEditableNotes('');
    };

    // ################################### End Custom Notes #############################################

    // Inline Editing Functionality
    const processRowUpdate = React.useCallback(async (newRow) => {
        // Make the HTTP request to save in the backend
        // console.log('[ClientListTableWithSearch][processRowUpdate] newRow:', newRow);
        // Prep ECF
        let filingForECFArray = [];

        if (newRow.filingForECF1 != null) filingForECFArray.push({ window_num: 1, action: newRow.filingForECF1 });
        if (newRow.filingForECF2 != null) filingForECFArray.push({ window_num: 2, action: newRow.filingForECF2 });
        if (newRow.filingForECF3 != null) filingForECFArray.push({ window_num: 3, action: newRow.filingForECF3 });

        // Prep Applicant Cohort
        let cohortIDs = newRow.client_applicantcohorts.map((ac) => ac.applicantcohort_id);
        let secondaryContactIDs = newRow.secondary_contacts.map((sc) => sc.id);
        let PrimaryContactID = newRow.PrimaryContact !== '' ? newRow.PrimaryContact : undefined;
        let cipaComplianceID =
            Array.isArray(newRow.cipa_compliance) || isNaN(newRow.cipa_compliance)
                ? cipaComplianceMenuItems.filter((ea_value) => ea_value.text === newRow.cipa_compliance)[0]['value']
                : newRow.cipa_compliance;

        if (newRow.PrimaryContact !== undefined && !Number.isInteger(Number(newRow.PrimaryContact))) {
            let primaryContactObject = primaryContactEditOptions.find(
                (element) => element.text === newRow.PrimaryContact
            );
            PrimaryContactID = primaryContactObject?.value;
        }
        // SaveClient() can use Client.fill_from_dict() to update these values based on the name of each key in this dict
        let clientInfo = {
            client_id: newRow.client_id,
            name: newRow.name,
            is_business_org: newRow.is_business_org,
            primary_contact_id: PrimaryContactID, // value of the SelectOptions(text/val) pair
            legacy_clientid: newRow.legacy_clientid,
            legacy_primary_ben: newRow.legacy_primary_ben,
            cybersecuritypilot_participation: newRow.cybersecuritypilot_participation,
            state_abbrev: newRow.state_abbrev,
            notes: newRow.notes,
            cipa_compliance: cipaComplianceID,
            start_date: newRow.start_date,
            end_date: newRow.end_date,
            secondaryContacts: secondaryContactIDs, // stored in separate table
            applicantCohortIds: cohortIDs, // stored in separate table
            filingForECFs: filingForECFArray, // stored in separate table
            bens: newRow.bensList, // stored in separate table
        };
        //console.log('ClientListTableWithSearch: clientInfo = ', clientInfo);
        const apiResponse = await clientAPI.SaveClient(clientInfo);
        //console.log(response);

        return apiResponse;
    }, []);

    // Inline Editing Error Handling
    const handleProcessRowUpdateError = React.useCallback((error) => {
        //console.log(error);
        //console.error(error.message);
        if (error.message !== "Cannot read properties of undefined (reading 'id')") {
            toast.error(error.message);
        }
    }, []);

    function getPrimaryContactName(params) {
        if (params.row.primary_contact) {
            return params.row.primary_contact.first_name + ' ' + params.row.primary_contact.last_name;
        } else {
            return '<NONE>';
        }
    }

    function getCIPAComplianceText(params) {
        if (params.row.cipa_compliance) {
            let cipa = params.row.cipa_compliance;
            if (cipa === 1) {
                return 'No Record';
            } else if (cipa === 2) {
                return 'Good';
            } else if (cipa === 3) {
                return 'Needs Improvement';
            } else if (cipa === 4) {
                return 'Needs Public Notice';
            } else if (cipa === 5) {
                return 'N/A';
            } else if (cipa === 6) {
                return 'Questionable';
            }
        } else {
            return '';
        }
    }

    const cipaComplianceMenuItems = [
        {
            text: '<blank>',
            value: '',
        },
        {
            text: 'Good',
            value: '2',
        },
        {
            text: 'Needs Improvement',
            value: '3',
        },
        {
            text: 'N/A',
            value: '5',
        },
    ];

    function getSecondaryContactNames(params) {
        if (!params.row.secondary_contacts || params.row.secondary_contacts.length === 0) {
            return '';
        }
        let ary = params.row.secondary_contacts.map((sc) => {
            return sc.first_name + ' ' + sc.last_name;
        });
        return ary.join(', ');
    }

    const getApplicantCohortNames = (params) => {
        if (!params.row.client_applicantcohorts || params.row.client_applicantcohorts.length === 0) {
            return '';
        }
        let ary = params.row.client_applicantcohorts.map((cac) => {
            return cac.applicantcohort.applicantcohort_name;
        });
        return ary.join(', ');
    };

    const benArraySortComparator = (v1, v2) => {
        // v1 and v2 are arrays of 0 or more BENs.
        let ben1 = v1.length > 0 ? v1[0] : 0;
        let ben2 = v2.length > 0 ? v2[0] : 0;
        return ben1 - ben2;
    };

    const dynamicColumnWidthWrapper = (original_cols, changing_cols) => {
        return original_cols.map((col) => ({
            ...col,
            width: changing_cols[col.field] || col.width,
        }));
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleSaveIconClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelIconClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const cybersecuritypilot_participation_items = [
        { value: '', text: 'None' },
        { value: 'Yes', text: 'Yes' },
        { value: 'No', text: 'No' },
        { value: 'Self', text: 'Self' },
        { value: 'Signed Proposal', text: 'Signed Proposal' },
        { value: 'Undecided', text: 'Undecided' },
        { value: 'Unknown', text: 'Unknown' },
    ];

    const convert_yyyymmddString_to_JSDate = (yyyymmddString) => {
        // Input: Date string in the format "yyyy-mm-dd".
        // Output: Javascript Date object.  Time is pushed forward as per the local timezone offset.
        // For DataGrid 'date' edit fields.
        if (!(typeof yyyymmddString === 'string')) {
            console.error('Error in convert_yyyymmddString_to_JSDate: Input was not a string.');
            return null;
        }
        let jsdate1 = new Date(yyyymmddString);
        return new Date(jsdate1.getTime() + jsdate1.getTimezoneOffset() * 60 * 1000);
    };

    const convert_JSDate_to_yyyymmddString = (jsdate) => {
        // Input: Javascript Date object.
        // Output: Date string in the format "yyyy-mm-dd".  Time was pushed back as per the local timezone offset.
        // For DataGrid 'date' edit fields.
        if (!(jsdate instanceof Date)) {
            console.error('Error in convert_JSDate_to_yyyymmddString: Input was not a JavaScript Date object.');
            return null;
        }
        let jsdate2 = new Date(jsdate.getTime() - jsdate.getTimezoneOffset() * 60 * 1000);
        return jsdate2.toISOString().split('T')[0];
    };

    // The original, untouched column definitions
    const originalColumns = [
        {
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            getActions: (params) => {
                const appdetailsbuttonkey = 'appDetailsButton-' + params.row.client_id;
                const appfundingsummarybuttonkey = 'appFundingSummaryButton-' + params.row.client_id;
                const appeditbuttonkey = 'appEditButton-' + params.row.client_id;
                const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <Tooltip title='Save' key={`save-${params.id}`}>
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label='Save'
                                onClick={handleSaveIconClick(params.id)}
                                // color='inherit'
                                style={{ color: '#4CAF50' }}
                            />
                        </Tooltip>,
                        <Tooltip title='Cancel' key={`cancel-${params.id}`}>
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label='Cancel'
                                aria-label='cancel'
                                onClick={handleCancelIconClick(params.id)}
                                // color='inherit'
                                style={{ color: 'red' }}
                            />
                        </Tooltip>,
                    ];
                }

                return [
                    <Tooltip title='Funding Summary' key={appfundingsummarybuttonkey}>
                        <GridActionsCellItem
                            icon={<AttachMoneyIcon />}
                            label='Funding Summary'
                            aria-label='funding-summary'
                            color='info'
                            onClick={() => {
                                handleFundingSummaryButtonClick(params.row.client_id, params.row.bensList);
                            }}
                            sx={{ margin: '0 -4px' }}
                        ></GridActionsCellItem>
                    </Tooltip>,
                    <Tooltip title='Details' key={appdetailsbuttonkey}>
                        <GridActionsCellItem
                            icon={<ArticleIcon />}
                            label='Details'
                            aria-label='details'
                            color='info'
                            onClick={() => handleDetailsButtonClick(params.row.client_id)}
                            sx={{ margin: '0 -4px' }}
                        ></GridActionsCellItem>
                    </Tooltip>,
                    <Tooltip title='Edit' key={appeditbuttonkey}>
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label='Edit'
                            aria-label='edit'
                            color='primary'
                            onClick={() => handleEditButtonClick(params.row.client_id)}
                            sx={{ margin: '0 -4px' }}
                        ></GridActionsCellItem>
                    </Tooltip>,
                ];
            },
            width: 100,
            filterable: false,
            sortable: false,
        },
        {
            headerName: 'BENs',
            field: 'BENs',
            valueGetter: (params) => params.row.bensList,
            valueFormatter: (params) => {
                if (!params.value || params.value.length === 0) {
                    return '';
                }
                return params.value.join(', ');
            },
            sortComparator: benArraySortComparator,
            filterable: true,
            width: 150,
        },
        {
            headerName: 'Name',
            field: 'name',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            editable: true,
            width: 200,
        },
        {
            headerName: 'Applicant Cohorts',
            field: 'ApplicantCohorts',
            valueGetter: getApplicantCohortNames,
            renderCell: (params) => {
                return params.row.client_applicantcohorts.map((cac) => {
                    return (
                        <Chip
                            key={params.row.client_id + '-' + cac.applicantcohort.applicantcohort_name}
                            label={cac.applicantcohort.applicantcohort_name}
                            sx={{ marginRight: '4px' }}
                        />
                    );
                });
            },
            width: 150,
        },
        {
            headerName: 'State',
            field: 'state_abbrev',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            editable: true,
            renderEditCell: (params) => {
                let stateValue = stateMSFilterOptions.filter((ea_value) => ea_value.value === params.row.state_abbrev);
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={stateValue}
                        field={params.field}
                        dropDownOptions={stateMSFilterOptions}
                    />
                );
            },
            width: 50,
        },
        {
            headerName: 'Primary Contact',
            field: 'PrimaryContact',
            valueGetter: getPrimaryContactName,
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            editable: true,
            renderEditCell: (params) => {
                let selectedid = params.row.primary_contact_id || 0;
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={selectedid}
                        field={params.field}
                        dropDownOptions={primaryContactEditOptions}
                    />
                );
            },
            width: 150,
        },
        {
            headerName: 'Secondary Contacts',
            field: 'SecondaryContacts',
            valueGetter: getSecondaryContactNames,
            renderCell: (params) => {
                return params.row.secondary_contacts.map((sc) => {
                    let scname = sc.first_name + ' ' + sc.last_name;
                    return (
                        <>
                            <Chip
                                key={params.row.client_id + '-' + scname}
                                label={scname}
                                sx={{ marginRight: '2px' }}
                            />
                        </>
                    );
                });
            },
            width: 150,
        },
        {
            // Applicant is a client if we are currently providing them services.
            headerName: 'Is a Client',
            field: 'isAClient',
            type: 'boolean',
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Is A
                    <br />
                    Client
                </Box>
            ),
            renderCell: (params) => <>{params.value === true ? 'Yes' : params.value === false ? 'No' : ''}</>,
            width: 75,
        },
        {
            headerName: 'Date Became Client',
            field: 'start_date',
            type: 'date',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            editable: true,
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }
                // Expected Input: The date data coming through the API is a string in the format "yyyy-mm-dd".
                // Output: It is converted to a JavaScript Date object (that is adjusted as per the local timezone offset).
                // It needs to be a Date object for display, filtering, and sorting.
                return convert_yyyymmddString_to_JSDate(params.value);
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    // Field is empty or has been emptied/cleared.
                    return { ...params.row, start_date: '' };
                }
                if (!(params.value instanceof Date)) {
                    // Input check
                    console.error('Expected params.value to be a JavaScript Date.');
                    return { ...params.row };
                }
                // Expected Input: If not null, params.value should be a JavaScript Date object.
                // Output: A date string in the format "yyyy-mm-dd" is returned.
                let ymdstring = convert_JSDate_to_yyyymmddString(params.value);
                return { ...params.row, start_date: ymdstring };
            },
            width: 127,
        },
        {
            headerName: 'Date Ended as Client',
            field: 'end_date',
            type: 'date',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            editable: true,
            valueGetter: (params) => {
                if (!params.value) {
                    return null;
                }
                // Expected Input: The date data coming through the API is a string in the format "yyyy-mm-dd".
                // Output: It is converted to a JavaScript Date object (that is adjusted as per the local timezone offset).
                // It needs to be a Date object for display, filtering, and sorting.
                return convert_yyyymmddString_to_JSDate(params.value);
            },
            valueSetter: (params) => {
                if (params.value === null) {
                    // Field is empty or has been emptied/cleared.
                    return { ...params.row, end_date: '' };
                }
                if (!(params.value instanceof Date)) {
                    // Input check
                    console.error('Expected params.value to be a JavaScript Date.');
                    return { ...params.row };
                }
                // Expected Input: If not null, params.value should be a JavaScript Date object.
                // Output: A date string in the format "yyyy-mm-dd" is returned.
                let ymdstring = convert_JSDate_to_yyyymmddString(params.value);
                return { ...params.row, end_date: ymdstring };
            },
            width: 117,
        },
        {
            headerName: '# of Current Services',
            field: 'currentClientServicesCount',
            type: 'number',
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    # of Current
                    <br />
                    Services
                </Box>
            ),
            renderCell: (params) => (
                <Tooltip title='Add/Remove Services' key={params.row.client_id + '-currentCSCount'}>
                    <Link href={'client-list/client-services/' + params.row.client_id}>{params.value}</Link>
                </Tooltip>
            ),
            width: 100,
        },
        {
            headerName: 'Is a Business Org',
            field: 'is_business_org',
            type: 'boolean',
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Is Business
                    <br />
                    Org
                </Box>
            ),
            renderCell: (params) => <>{params.value === true ? 'Yes' : params.value === false ? 'No' : ''}</>,
            width: 90,
        },
        {
            headerName: 'Notes',
            field: 'notes',
            type: 'string',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            minPopupWidth: 500,
            // ORIGINAL RENDERCELL
            // renderCell: (params) => (
            //     <NotesCellDisplay
            //         notes={params.value}
            //         minPopupWidth={500}
            //         computedWidth={params.colDef.computedWidth}
            //         rowHeightPref={rowHeightPref}
            //     />
            // ),

            // NEW RENDERCELL
            renderCell: (params) => (
                <NotesCellDisplay
                    notes={params.value}
                    noteIsEditable={true}
                    minPopupWidth={500}
                    computedWidth={params.colDef.computedWidth}
                    rowHeightPref={rowHeightPref}
                    onEdit={() =>
                        handleEditNotesEvent(
                            params.row.client_id,
                            params.field,
                            params.value,
                            params.row.bensList,
                            params.row.name
                        )
                    }
                    isInViewMode={apiRef.current.getRowMode(params.id) === 'view'}
                />
            ),
            renderEditCell: (params) => <InlineEditTextarea1 {...params} />,
            width: 250,
        },
        {
            headerName: 'ECF 1',
            field: 'filingForECF1',
            width: 90,
        },
        {
            headerName: 'ECF 2',
            field: 'filingForECF2',
            width: 90,
        },
        {
            headerName: 'ECF 3',
            field: 'filingForECF3',
            width: 90,
        },
        {
            headerName: 'Cyber Pilot',
            field: 'cybersecuritypilot_participation',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            editable: true,
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Cyber
                    <br />
                    Pilot
                </Box>
            ),
            renderEditCell: (params) => {
                const defaultValue = cybersecuritypilot_participation_items[0].value;
                const nval = params.row.cybersecuritypilot_participation || defaultValue;
                return (
                    <InlineEditDropdown
                        id={params.id}
                        field={params.field}
                        value={nval}
                        dropDownOptions={cybersecuritypilot_participation_items}
                    />
                );
            },
            width: 80,
        },
        {
            headerName: 'CIPA Compliance',
            field: 'cipa_compliance',
            headerClassName: 'Editable-NotRequired',
            cellClassName: 'InlineEditable',
            editable: true,
            valueGetter: getCIPAComplianceText,
            renderEditCell: (params) => {
                let cipaItem = cipaComplianceMenuItems.find((ea_value) => ea_value.text === params.row.cipa_compliance);

                let cipaValue = cipaItem ? cipaItem.value : '';
                return (
                    <InlineEditDropdown
                        id={params.id}
                        value={cipaValue}
                        field={params.field}
                        dropDownOptions={cipaComplianceMenuItems}
                    />
                );
            },
            width: 140,
        },
        {
            headerName: 'Primary BEN (legacy)',
            field: 'legacy_primary_ben',
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Primary BEN
                    <br />
                    (Legacy)
                </Box>
            ),
            width: 100,
        },
        {
            headerName: 'clientId (legacy)',
            field: 'legacy_clientid',
            renderHeader: (params) => (
                <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                    Client ID
                    <br />
                    (Legacy)
                </Box>
            ),
            width: 100,
        },
    ];

    const ecfMSFilterOptions = [
        { text: 'Yes', value: 0 },
        { text: 'No', value: 1 },
    ];

    const editOptions = {
        applicantCohortMSFilterOptions,
        cipaComplianceMSFilterOptions,
        cyberSecurityPilotParticipationOptions,
        ecfMSFilterOptions,
        nameMSFilterOptions,
        primaryContactMSFilterOptions,
        secondaryContactMSFilterOptions,
        stateMSFilterOptions,
    };

    const editOptionsMapping = {
        ApplicantCohorts: 'applicantCohortMSFilterOptions',
        cipa_compliance: 'cipaComplianceMSFilterOptions',
        cybersecuritypilot_participation: 'cyberSecurityPilotParticipationOptions',
        filingForECF1: 'ecfMSFilterOptions',
        filingForECF2: 'ecfMSFilterOptions',
        filingForECF3: 'ecfMSFilterOptions',
        name: 'nameMSFilterOptions',
        PrimaryContact: 'primaryContactMSFilterOptions',
        SecondaryContacts: 'secondaryContactMSFilterOptions',
        state_abbrev: 'stateMSFilterOptions',
    };

    const columnsV2 = React.useCallback(() => {
        let col_array = dynamicColumnWidthWrapper(
            // Uncomment for column-to-column filter comparisons
            // IsBeforeXDaysWrapper(
            //     IsAfterXDaysWrapper(
            //         IsNotAnyOfWrapper(
            //             IsAnyOfWrapper(
            //                 IsLessThanOrEqualToColumnWrapper(
            //                     IsLessThanColumnWrapper(
            //                         IsGreaterThanOrEqualToColumnWrapper(
            //                             IsGreaterThanColumnWrapper(
            //                                 IsNotEqualToColumnWrapper(
            //                                     IsEqualToColumnWrapper(
            //                                         doesNotContainWrapper(
            //                                             removeAnyBooleanFilterValueWrapper(originalColumns)
            //                                         )
            //                                     )
            //                                 )
            //                             )
            //                         )
            //                     )
            //                 ),
            //                 editOptions,
            //                 editOptionsMapping
            //             ),
            //             editOptions,
            //             editOptionsMapping
            //         )
            //     )
            // ),

            // IsNotAnyOfWrapper(
            //     IsAnyOfWrapper(
            //         doesNotContainWrapper(removeAnyBooleanFilterValueWrapper(originalColumns)),
            //         editOptions,
            //         editOptionsMapping
            //     ),
            //     editOptions,
            //     editOptionsMapping
            // ),

            IsNotAnyOfWrapper(
                IsAnyOfWrapper(
                    IsLessThanOrEqualToColumnWrapper(
                        IsLessThanColumnWrapper(
                            IsGreaterThanOrEqualToColumnWrapper(
                                IsGreaterThanColumnWrapper(
                                    IsNotEqualToColumnWrapper(
                                        IsEqualToColumnWrapper(
                                            doesNotContainWrapper(removeAnyBooleanFilterValueWrapper(originalColumns))
                                        )
                                    )
                                )
                            )
                        )
                    ),
                    editOptions,
                    editOptionsMapping
                ),
                editOptions,
                editOptionsMapping
            ),
            currentColumnWidths
        );

        let col_order = currentColumnOrder;

        if (col_order) {
            let sorted_cols = col_array.sort((a, b) => {
                let indexA = col_order.findIndex((element) => element === a.field);
                let indexB = col_order.findIndex((element) => element === b.field);
                return indexA - indexB;
            });
            return sorted_cols;
        } else {
            return col_array;
        }
    }, [
        applicantCohortMSFilterOptions,
        cipaComplianceMSFilterOptions,
        cyberSecurityPilotParticipationOptions,
        nameMSFilterOptions,
        primaryContactMSFilterOptions,
        secondaryContactMSFilterOptions,
        stateMSFilterOptions,
        editDropdownOptionsRendered,
        currentColumnWidths,
        currentColumnOrder,
        rowModesModel,
    ]);

    if (clientList === undefined) {
        return <h3>ERROR retrieving clients</h3>;
    }
    // Potential - This is
    return (
        <>
            <ClientListSearchBox
                clientAPI={clientAPI}
                userAPI={userAPI}
                handleFieldsInitialized={setSearchParameters}
                handleSearchButtonClick={setSearchParameters}
                defaultBen={params.ben}
                currentUserID={currentUserID}
            />
            <ToastContainer theme='colored' autoClose={false} closeOnClick />
            <Box sx={{ paddingBottom: '4px' }}>
                <Button
                    variant='contained'
                    size='small'
                    style={{ marginRight: '1em', backgroundColor: '#4CAF50', color: 'white' }}
                    onClick={() => saveViewButtonClicked()}
                >
                    {selectedViewName ? 'Update View' : 'Save View'}
                </Button>
                <Button variant='contained' size='small' onClick={() => showHideECFColumns()}>
                    {showHideECFColumnsButtonText}
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => autoFixedRowHeight()}
                    sx={{ marginLeft: '1em' }}
                >
                    {autoFixedColumnsButtonText}
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => resetColumnOrderToDefault()}
                    sx={{ marginLeft: '1em' }}
                >
                    Reset Column Order
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => {
                        resetColumnVisibilityToDefault();
                    }}
                    sx={{ marginLeft: '1em' }}
                >
                    Reset Column Visibility
                </Button>
                <Button variant='contained' size='small' onClick={() => showAll()} sx={{ marginLeft: '1em' }}>
                    Show All Columns
                </Button>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => resetFiltersToDefault()}
                    sx={{ marginLeft: '1em' }}
                >
                    Clear all Filters
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    style={{ float: 'right', backgroundColor: '#4CAF50', color: 'white' }}
                    onClick={() => handleCreateButtonClick()}
                >
                    Create New Record
                </Button>
                {usedCacheOnLoad && (
                    <>
                        <CircularProgress sx={{ marginLeft: '25px' }} size='.75rem' />
                        <Typography variant='span' sx={{ color: 'gray', marginLeft: '10px' }}>
                            <em>Most recent data loading - Inline editing temporarily disabled</em>
                        </Typography>
                    </>
                )}
            </Box>
            <DataGridPro
                experimentalFeatures={{ newEditingApi: true }}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                columns={columnsV2()}
                rows={clientData}
                getRowId={(row, index) =>
                    row.client_id ? `${row.client_id.toString() + '-' + index}` : index.toString()
                }
                loading={dataIsLoading && clientData.length === 0}
                components={{
                    Toolbar: CustomDatagridToolbarARCL,
                    NoRowsOverlay: () => (
                        <Stack
                            height='100%'
                            display='flex'
                            justifyContent='flex-start'
                            flexDirection='column'
                            alignItems='center'
                            paddingTop='5%'
                        >
                            <div style={{ color: 'red', fontWeight: 'bold' }}>Your search returned no records.</div>
                            <div style={{ color: 'red', fontWeight: 'bold' }}>Please adjust the Search criteria.</div>
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack
                            height='100%'
                            display='flex'
                            justifyContent='flex-start'
                            flexDirection='column'
                            alignItems='center'
                            paddingTop='5%'
                        >
                            <div style={{ color: 'blue', fontWeight: 'bold' }}>No records matched your filters.</div>
                            <div style={{ color: 'blue', fontWeight: 'bold' }}>Please adjust the DataGrid filters.</div>
                        </Stack>
                    ),
                }}
                componentsProps={{
                    toolbar: {
                        appliesTo,
                        currentColumnOrder,
                        currentColumnWidths,
                        currentSavedViews,
                        defaultFilter,
                        defaultHiddenColumns,
                        defaultPinnedColumns,
                        defaultSort,
                        densityPref,
                        editingViewName,
                        filterPrefs,
                        getOriginalColumnOrderFromStorage,
                        getOriginalColumnWidthsFromStorage,
                        handleDeleteSavedView,
                        handleDensityChange,
                        handleEditSavedViewName,
                        handleSelectSavedView,
                        hiddenColumnPrefs,
                        pinnedColumnPrefs,
                        resetColumnOrderToDefault,
                        resetColumnWidthToDefault,
                        resetViewToDefaults,
                        selectedViewName,
                        setEditingViewName,
                        sortPrefs,
                        userID,
                    },
                }}
                editMode='row'
                isCellEditable={() => !usedCacheOnLoad}
                density={densityPref}
                getRowHeight={getRowHeight}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                disableSelectionOnClick
                pagination
                rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000, 2000]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                columnVisibilityModel={JSON.parse(hiddenColumnPrefs)}
                onColumnVisibilityModelChange={(newVizModel) => handleOnColumnVisibilityModelChange(newVizModel)}
                filterModel={JSON.parse(filterPrefs)}
                onFilterModelChange={(newFilterModel) => handleFilterPrefs(newFilterModel)}
                sortModel={JSON.parse(sortPrefs)}
                onSortModelChange={(newSortModel) => handleOnSortModelChange(newSortModel)}
                pinnedColumns={JSON.parse(pinnedColumnPrefs)}
                onColumnWidthChange={handleOnColumnWidthChange}
                onPinnedColumnsChange={(newPinnedModel) => handlePinnedPrefs(newPinnedModel)}
                onColumnOrderChange={handleOnColumnOrderChangeV2}
                apiRef={apiRef}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                sx={{
                    minHeight: '300px',
                    height: '75vh',
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: '1px',
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: '10px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                        py: '20px',
                    },
                    boxShadow: 2,
                    border: 2,
                    borderColor: 'primary.light',
                    '& .MuiDataGrid-cell': {
                        border: '1px solid rgba(224, 224, 224, 1)',
                    },
                    '& .MuiDataGrid-row:nth-of-type(2n)': {
                        backgroundColor: '#f0f2f4',
                    },
                    '& .MuiDataGrid-row:nth-of-type(2n+1)': {
                        backgroundColor: '#fdfffe',
                    },
                    '& .MuiDataGrid-columnHeaders, .MuiDataGrid-footerContainer': {
                        backgroundColor: '#fdfffe',
                    },
                }}
            />

            <NotesEditDialog
                open={openNotesEditDialog}
                title={notesEditDialogTitle}
                label={notesEditDialogLabel}
                initialNotes={editableNotes}
                username={usernameForNotesEditDialog}
                onSave={handleNotesEditDialogSave}
                onCancel={handleNotesEditDialogCancel}
                onClose={handleNotesEditDialogClose}
            />

            <PromptUpdateOrSaveNewViewDialog
                isDialogOpen1={isDialogOpen1}
                setIsDialogOpen1={setIsDialogOpen1}
                landing={landing}
                dialogMessage={dialogMessage}
                handleOnlyUpdateView={handleOnlyUpdateView}
                handleOnlySaveAsNewView1={handleOnlySaveAsNewView1}
                handleCancelButtonClick1={handleCancelButtonClick1}
                showTextField1={showTextField1}
                textFieldValue={textFieldValue}
                setTextFieldValue={setTextFieldValue}
                handleKeyDown={handleKeyDown}
                handleOnlySaveAsNewView2={handleOnlySaveAsNewView2}
            />
            {promptSaveNewView()}
        </>
    );
}

function ClientListSearchBox({
    clientAPI,
    userAPI,
    handleFieldsInitialized,
    handleSearchButtonClick,
    defaultBen,
    currentUserID,
}) {
    //################################################################################
    const LS_INCREMENT = 1;
    const SS_INCREMENT = 1;
    const CLIENT_LIST_SEARCH_LS_NAME = 'cedCLSearchFieldValuesLS' + LS_INCREMENT;
    const CLIENT_LIST_SEARCH_SS_NAME = 'cedCLSearchFieldValuesSS' + SS_INCREMENT;
    const [searchFieldValuesInLS, setSearchFieldValuesInLS] = useLocalStorage(CLIENT_LIST_SEARCH_LS_NAME, '');
    //################################################################################

    const [areFieldsInitialized, setAreFieldsInitialized] = React.useState(false);
    const auth = useAuthContext();
    const [defaultUserID, setDefaultUserID] = React.useState(null);

    const accordianCollapsedText = 'Click to Search for Clients';
    const accordianExpandedText = 'Client Search Form';
    const [accordianTitle, setAccordianTitle] = React.useState(accordianCollapsedText);

    // Multi-select dropdown getters/setters:
    const [primaryContactMSOptions, setPrimaryContactMSOptions] = React.useState([]);
    const [secondaryContactMSOptions, setSecondaryContactMSOptions] = React.useState([]);
    const [applicantCohortMSOptions, setApplicantCohortMSOptions] = React.useState([]);
    const [statesMSOptions, setStatesMSOptions] = React.useState([]);
    const [servicesMSOptions, setServicesMSOptions] = React.useState([]);
    const [teamsMSOptions, setTeamsMSOptions] = React.useState([]);
    const [cipaComplianceMSOptions, setCipaComplianceMSOptions] = React.useState([]);

    // General Client SearchBox state:
    const [ben, setBen] = React.useState(defaultBen ?? '');
    const [name, setName] = React.useState('');
    const [applicantCohorts, setApplicantCohorts] = React.useState([]);
    const [isAClient, setIsAClient] = React.useState(true);
    const [states, setStates] = React.useState([]);
    const [primaryContacts, setPrimaryContacts] = React.useState([]);
    const [secondaryContacts, setSecondaryContacts] = React.useState([]);
    const [cipaCompliance, setCipaCompliance] = React.useState([]);
    const [services, setServices] = React.useState([]);
    const [teamIDs, setTeamIDs] = React.useState([]);
    const [cyberSecurityPilotParticipation, setCyberSecurityPilotParticipation] = React.useState(''); // Only use potential in setSearchFieldPrefs() if user filters for this field
    const [clientStartDateFrom, setClientStartDateFrom] = React.useState('');
    const [clientStartDateTo, setClientStartDateTo] = React.useState('');
    const [clientEndDateFrom, setClientEndDateFrom] = React.useState('');
    const [clientEndDateTo, setClientEndDateTo] = React.useState('');

    //################################### SAVED SEARCHES #############################################
    const [userID, setUserID] = React.useState([]);
    const [currentSavedSearches, setCurrentSavedSearches] = React.useState([]);
    const [selectedSearchName, setSelectedSearchName] = React.useState('');
    const [isSelectOpen, setIsSelectOpen] = React.useState(false);
    const [editingSearchName, setEditingSearchName] = React.useState('');

    const [isDialogOpen1, setIsDialogOpen1] = React.useState(false);
    const [isDialogOpen2, setIsDialogOpen2] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [landing, setLanding] = React.useState(true);
    const [showTextField1, setShowTextField1] = React.useState(false);
    const [showTextField2, setShowTextField2] = React.useState(true);
    const [textFieldValue, setTextFieldValue] = React.useState('');
    const [savedSearchObject, setSavedSearchObject] = React.useState({});
    //################################### END SAVED SEARCHES #############################################

    //=====  Seach Box State (Deadline)  =====
    // const [deadlineFieldName, setDeadlineFieldName] = React.useState(null);
    // const [deadlineFieldOperator, setDeadlineFieldOperator] = React.useState(null);
    // const [deadlineFieldDays, setDeadlineFieldDays] = React.useState(null);
    // const [deadlineValue, setDeadlineValue] = React.useState(deadlineFieldDays ?? '');
    // const [calculatedDeadlineDate, setCalculatedDeadlineDate] = React.useState('');
    // const [deadlineFieldsCount, setDeadlineFieldsCount] = React.useState(0);
    // const [isDeadlineAccordionSelected, setIsDeadlineAccordionSelected] = React.useState(false);
    // const [valueInSearchRanges, setValueInSearchRanges] = React.useState(false);

    // Function used to reset the search values to their defaults
    const setDefaultSearchValues = (defaultUserID) => {
        setBen('');
        setName('');
        setSelectedSearchName('');
        setApplicantCohorts([]);
        setIsAClient(true);
        setStates([]);
        setSecondaryContacts([]);
        setCipaCompliance([]);
        setServices([]);
        setTeamIDs([]);
        setClientStartDateFrom('');
        setClientStartDateTo('');
        setClientEndDateFrom('');
        setClientEndDateTo('');

        if (defaultUserID != null) {
            setPrimaryContacts([defaultUserID]);
        } else {
            setPrimaryContacts([]);
        }
    };

    // Creates an object of key/value pairs of search field data
    // potential
    const createSearchParametersObject = () => {
        return {
            ben: ben,
            name: name,
            selectedSearchName: selectedSearchName,
            applicantCohorts: applicantCohorts,
            isAClient: isAClient,
            states: states,
            primaryContacts: primaryContacts,
            secondaryContacts: secondaryContacts,
            cipaCompliance: cipaCompliance,
            services: services,
            teamIDs: teamIDs,
            clientStartDateFrom: clientStartDateFrom,
            clientStartDateTo: clientStartDateTo,
            clientEndDateFrom: clientEndDateFrom,
            clientEndDateTo: clientEndDateTo,
            cybersecuritypilot_participation: cyberSecurityPilotParticipation,
            // deadlineFieldName: deadlineFieldName,
            // deadlineFieldOperator: deadlineFieldOperator,
            // deadlineFieldDays: deadlineFieldDays,
            // calculatedDeadlineDate: calculatedDeadlineDate
        };
    };

    // Called when LS/SS key/value pairs are found (via handleSearchButtonClicked) to set the current states to those KP pairs
    const setSearchFieldPrefs = (parsedSP) => {
        // Sets the default values for each of search parameters
        setDefaultSearchValues(defaultUserID);

        // Fill in search values with real data
        // Potential
        try {
            if (parsedSP.ben != null) {
                setBen(parsedSP.ben);
            }
            if (parsedSP.name != null) {
                setName(parsedSP.name);
            }
            if (parsedSP.selectedSearchName) {
                setSelectedSearchName(parsedSP.selectedSearchName);
            }
            if (parsedSP.applicantCohorts != null && Array.isArray(parsedSP.applicantCohorts)) {
                setApplicantCohorts(parsedSP.applicantCohorts);
            }
            if (parsedSP.isAClient != null && (parsedSP.isAClient === true || parsedSP.isAClient === false)) {
                setIsAClient(parsedSP.isAClient);
            }
            if (parsedSP.states != null && Array.isArray(parsedSP.states)) {
                setStates(parsedSP.states);
            }
            if (parsedSP.services != null && Array.isArray(parsedSP.services)) {
                setServices(parsedSP.services);
            }
            if (parsedSP.teamIDs != null && Array.isArray(parsedSP.teamIDs)) {
                setTeamIDs(parsedSP.teamIDs);
            }
            if (parsedSP.clientStartDateFrom != null && parsedSP.clientStartDateFrom !== '') {
                setClientStartDateFrom(parsedSP.clientStartDateFrom);
            }
            if (parsedSP.clientStartDateTo != null && parsedSP.clientStartDateTo !== '') {
                setClientStartDateTo(parsedSP.clientStartDateTo);
            }
            if (parsedSP.clientEndDateFrom != null && parsedSP.clientEndDateFrom !== '') {
                setClientEndDateFrom(parsedSP.clientEndDateFrom);
            }
            if (parsedSP.clientEndDateTo != null && parsedSP.clientEndDateTo !== '') {
                setClientEndDateTo(parsedSP.clientEndDateTo);
            }
            if (parsedSP.primaryContacts != null && Array.isArray(parsedSP.primaryContacts)) {
                setPrimaryContacts(parsedSP.primaryContacts);
            }
            if (parsedSP.secondaryContacts != null && Array.isArray(parsedSP.secondaryContacts)) {
                setSecondaryContacts(parsedSP.secondaryContacts);
            }
            if (parsedSP.cipaCompliance != null && Array.isArray(parsedSP.cipaCompliance)) {
                setCipaCompliance(parsedSP.cipaCompliance);
            }
        } catch (error) {
            console.error('error: ', error);
            toast.error(error);
            return false;
        }
    };

    //################################################################################
    //=====  Functions to retrieve, save, and clear search field values from/to/in storage  =====

    // Attempts to pull from SS then LS
    const retrieveSearchFieldValuesJSONFromStorage = () => {
        let searchFieldValuesInSS = sessionStorage.getItem(CLIENT_LIST_SEARCH_SS_NAME);
        if (searchFieldValuesInSS) {
            return searchFieldValuesInSS;
        }

        if (searchFieldValuesInLS) {
            return searchFieldValuesInLS;
        }
        return null;
    };

    // Saves SFVs to session & local storage
    const saveSearchFieldValuesJSONToStorage = (json) => {
        setSearchFieldValuesInLS(json);
        sessionStorage.setItem(CLIENT_LIST_SEARCH_SS_NAME, json);
    };

    // Clears out the values of the storage keys
    const clearSearchFieldValuesStorage = () => {
        setSearchFieldValuesInLS('');
        sessionStorage.setItem(CLIENT_LIST_SEARCH_SS_NAME, '');
    };

    // Clears out the values of the storage keys & sets default values
    const resetToDefaults = () => {
        clearSearchFieldValuesStorage();
        setTextFieldValue('');
        setDefaultSearchValues(defaultUserID);
    };
    //################################################################################

    // Gets all of the user's saved searches
    const getSavedSearches = async (ap) => {
        const searchResults = await userAPI.GetSavedSearches(ap);
        const parsedSearchResults = JSON.parse(searchResults);
        // console.log('[ClientListTableWithSearch][getSavedSearchesFN] parsedSearchResults:', parsedSearchResults);

        // Defines the base layout for all retrieved searches
        const structuredSearches = parsedSearchResults.map((ea_row) => ({
            search_id: ea_row.id,
            search_name: ea_row.search_name,
            parameters: ea_row,
        }));
        structuredSearches.sort((a, b) => a.search_name.localeCompare(b.search_name));
        console.log('[ClientListTableWithSearch][getSavedSearchesFN] structuredSearches:', structuredSearches);
        setCurrentSavedSearches(structuredSearches);
    };

    // Populates the multi-select options
    React.useEffect(() => {
        const getCurrentUserID = async (cognitoID) => {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetDatabaseIDForUser';
            const queryStringParameters = { queryStringParameters: { cognito_id: cognitoID } };

            const idResponse = await API.get(apiName, path, queryStringParameters);
            return idResponse;
        };

        // Populates drop-down states with real data & sets search parameters w. cached LS/SS values or default values upon component mounting/rendering
        // Potential - Calls setSearchFieldPrefs, which populates the values of createSearchParametersObject, which populates searchParametersForAPI passed to searchClients()
        const getSearchOptionsAndInitialize = async () => {
            try {
                // Get dropdown and multi-select options
                // Returns a data-populated object of drop-down options, then sets the corresponding states with it
                let searchOptions = await clientAPI.GetClientListSearchOptions();
                // console.log('ClientListTableWithSearch: searchOptions = ', searchOptions);
                setPrimaryContactMSOptions(searchOptions.employees);
                setSecondaryContactMSOptions(searchOptions.employees);
                setApplicantCohortMSOptions(searchOptions.applicantCohorts);
                setServicesMSOptions(searchOptions.services);
                setTeamsMSOptions(searchOptions.teams);
                setStatesMSOptions(searchOptions.states);
                setCipaComplianceMSOptions(searchOptions.cipaCompliance);

                // Get the user's userId
                let userID = await getCurrentUserID(auth.cognitoID);
                setUserID(userID);

                // Check if that user (id) in the "Primary Contacts" options array..
                let pcoption = searchOptions.employees.find((pc) => pc.value === userID);
                if (!pcoption) {
                    userID = null;
                }
                setDefaultUserID(userID);

                // Initialize search box to previous values or defaults
                let sfv_json = JSON.parse(retrieveSearchFieldValuesJSONFromStorage());
                if (sfv_json) {
                    setSearchFieldPrefs(sfv_json);
                    setSelectedSearchName(sfv_json.selectedSearchName);
                } else {
                    setDefaultSearchValues(userID);
                    setBen(defaultBen ?? '');
                }

                // Indicates that whether from storage or default values, all search parameters have been instantiated
                setAreFieldsInitialized(true);
            } catch (error) {
                console.error('error: ', error);
                toast.error(error);
            }
        };

        getSearchOptionsAndInitialize();
        getSavedSearches(appliesTo);
    }, []);

    // Checks if the search parameters initialization (with data or defaults) process was successful
    // Potential
    React.useEffect(() => {
        if (areFieldsInitialized === true) {
            handleFieldsInitialized(createSearchParametersObject()); // const [searchParameters, setSearchParameters] = React.useState(null);
        }
    }, [areFieldsInitialized]);

    // Handlers to set the state when the user clicks on a drop-down option
    // Potential - these are the onChange/handleChange functions of the client search form
    const handleAccordianOnChange = (event, expanded) => {
        setAccordianTitle(expanded ? accordianExpandedText : accordianCollapsedText);
    };

    const handleApplicantCohortChange = (event, selectedValues) => {
        setApplicantCohorts(selectedValues);
    };

    const handleServicesChange = (event, selectedValues) => {
        setServices(selectedValues);
    };

    const handleTeamsChange = (event, selectedValues) => {
        setTeamIDs(selectedValues);
    };

    const handleStatesChange = (event, selectedValues) => {
        setStates(selectedValues);
    };

    const handlePrimaryContactChange = (event, selectedValues) => {
        setPrimaryContacts(selectedValues);
    };

    const handleSecondaryContactChange = (event, selectedValues) => {
        setSecondaryContacts(selectedValues);
    };

    const handleCipaComplianceChange = (event, selectedValues) => {
        setCipaCompliance(selectedValues);
    };

    // const handleDeadlineFieldStatusChange = (event) => {
    //     setDeadlineFieldName(event);
    // };

    // const handleDeadlineOperatorStatusChange = (event) => {
    //     setDeadlineFieldOperator(event);
    // };

    // const handleDeadlineDaysChange = (event) => {
    //     setDeadlineFieldDays(event);
    // };

    // const checkForDeadlineValue = (stateList) => {
    //     return stateList.some((ea_state) => ea_state !== '' && ea_state !== null && ea_state !== undefined);
    // };

    // React.useEffect(() => {
    //     setValueInSearchRanges(
    //         checkForDeadlineValue([clientStartDateFrom, clientStartDateTo, clientEndDateFrom, clientEndDateTo])
    //     );
    // }, [clientStartDateFrom, clientStartDateTo, clientEndDateFrom, clientEndDateTo]);

    // // For Deadlines
    // React.useEffect(() => {
    //     const totals = (deadlineFieldName ? 1 : 0) + (deadlineFieldOperator ? 1 : 0) + (deadlineValue ? 1 : 0);

    //     const anySelected = totals > 0;

    //     if (anySelected !== isDeadlineAccordionSelected) {
    //         setIsDeadlineAccordionSelected(anySelected);
    //     }

    //     if (anySelected) {
    //         setDeadlineFieldsCount(totals);
    //     } else if (deadlineFieldsCount !== 0) {
    //         setDeadlineFieldsCount(0);
    //     }
    // }, [deadlineFieldName, deadlineFieldOperator, deadlineValue, deadlineFieldsCount, isDeadlineAccordionSelected]);

    //################################### SAVED SEARCHES #############################################
    function endsWithSearch(searchName) {
        const lowercaseName = searchName.trim().toLowerCase();
        return lowercaseName.endsWith('search') || lowercaseName.split(-6).pop().endsWith('search');
    }

    // Check if the search name already exists
    const searchNameExists = (searchName) => {
        return currentSavedSearches.some((ea_search) => ea_search.search_name === searchName);
    };

    // Components rendered when the user begins to edit a MenuItem
    const EditableMenuItem = ({ ea_search, onSave, onCancel }) => {
        const [editValue, setEditValue] = React.useState(ea_search.search_name);

        const handleKeyDown = (event) => {
            // Stops the select component from navigating while typing
            event.stopPropagation();
            if (event.key === 'Enter') {
                onSave(ea_search.search_name, editValue);
            }
        };

        return (
            <MenuItem>
                <TextField
                    value={editValue}
                    onChange={(event) => setEditValue(event.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <IconButton onClick={() => onSave(ea_search.search_name, editValue)}>
                    <DoneIcon fontSize='inherit' />
                </IconButton>
                <IconButton onClick={onCancel}>
                    <CancelIcon fontSize='inherit' />
                </IconButton>
            </MenuItem>
        );
    };

    const handleSaveSearch = async (sp_obj) => {
        setSavedSearchObject(sp_obj);

        if (selectedSearchName !== '' && selectedSearchName !== null) {
            // Search for duplicate/determine if the user has already selected a search
            const duplicateSearchIndex = currentSavedSearches.findIndex(
                (ea_search) => ea_search.search_name === selectedSearchName
            );

            // If a duplicate was found/the user has already selected a search
            if (duplicateSearchIndex !== -1) {
                openDialog1(selectedSearchName);
            }
        } else {
            openDialog2();
            promptSaveNewSearch();
        }
    };

    const handleOnlyUpdateSearch = async () => {
        let message = 'update';
        let newOrUpdatedSearchName = selectedSearchName;
        let tempCurrentSavedSearches = [...currentSavedSearches];

        const duplicateSearchIndex = currentSavedSearches.findIndex(
            (ea_search) => ea_search.search_name === newOrUpdatedSearchName
        );

        // Finds the matching search object
        const matchingSavedSearch = currentSavedSearches[duplicateSearchIndex];

        // Building the updated search object (state updates too slowly to add searchName to CSPO)
        const replacementSearch = {
            search_id: matchingSavedSearch.parameters.id,
            search_name: newOrUpdatedSearchName,
            parameters: savedSearchObject,
        };

        // Update state
        tempCurrentSavedSearches[duplicateSearchIndex] = replacementSearch;
        const response = await userAPI.SaveSearch(
            userID,
            message,
            appliesTo,
            tempCurrentSavedSearches.at(duplicateSearchIndex)
        );

        if (response) {
            setLanding(false);
            setShowTextField1(false);
            setIsDialogOpen1(false);

            let successMessage = endsWithSearch(newOrUpdatedSearchName)
                ? `Successfully updated the ${newOrUpdatedSearchName}`
                : `Successfully updated the ${newOrUpdatedSearchName} saved search`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            let failedMessage = endsWithSearch(newOrUpdatedSearchName)
                ? `Failed to update the ${newOrUpdatedSearchName}`
                : `Failed to update the ${newOrUpdatedSearchName} saved search`;

            toast.error(failedMessage);
        }
        getSavedSearches(appliesTo);
        setLanding(true);
    };

    const handleOnlySaveAsNewSearch1 = () => {
        setLanding(false);
        setShowTextField1(true);
    };

    const handleOnlySaveAsNewSearch2 = async () => {
        if (textFieldValue === null) return;
        if (textFieldValue === '') {
            toast.error('Saved search name cannot be empty!');
            return;
        }

        const message = 'new';
        const searchName = textFieldValue.trim();
        const tempCurrentSavedSearches = [...currentSavedSearches];

        if (searchNameExists(searchName)) {
            toast.error(
                `A saved search with the name ${searchName} already exists. Please try again with a different name.`
            );
            return;
        }

        // Continue with adding the new view
        const newSearch = {
            search_name: searchName,
            parameters: savedSearchObject,
        };
        // newSearch['parameters']['searchName'] = searchName;
        newSearch['parameters']['selectedSearchName'] = searchName;
        tempCurrentSavedSearches.push(newSearch);

        const response = await userAPI.SaveSearch(userID, message, appliesTo, tempCurrentSavedSearches.at(-1));
        if (response) {
            setShowTextField1(false);
            setShowTextField2(false);

            setIsDialogOpen1(false);
            setIsDialogOpen2(false);

            let successMessage = endsWithSearch(searchName)
                ? `Successfully created the ${searchName}`
                : `Successfully created the ${searchName} saved search`;

            toast.success(successMessage, {
                autoClose: 3000,
            });
        } else {
            let failedMessage = endsWithSearch(searchName)
                ? `Failed to create the ${searchName}`
                : `Failed to create the ${searchName} saved search`;

            toast.error(failedMessage);
        }
        setLanding(true);
        getSavedSearches(appliesTo);
        setSelectedSearchName(searchName);
    };

    // Called when the user selects a search from the dropdown
    const handleSelectSavedSearch = (search_name) => {
        setSelectedSearchName(search_name);

        // Finds the matching search object
        const retrieved_search = currentSavedSearches.find((ea_search) => ea_search.search_name === search_name);
        if (retrieved_search) {
            setSearchFieldPrefs(retrieved_search.parameters.search_filters); // parses the values into searchbar
        } else {
            return;
        }
    };

    // TODO: change searchName to selectedSearchName ?
    // Called when the user clicks the edit icon
    const handleEditSavedSearchName = async (old_name, new_name) => {
        const message = 'update';
        if (!new_name) {
            return;
        }

        // Check if the user entered a new name that's different from the old one
        if (new_name.trim() && new_name !== old_name) {
            // Check if the new name/search_name already exists for the other saved searches
            if (currentSavedSearches.some((ea_search) => ea_search.search_name === new_name)) {
                alert('A saved search with this name already exists. Please try again with a different name.');
                return;
            }

            // Finding the location of the search we want to edit
            const duplicateSearchIndex = currentSavedSearches.findIndex(
                (ea_search) => ea_search.search_name === old_name
            );

            // If a duplicate was found
            if (duplicateSearchIndex !== -1) {
                // Returns an array of objects with the previous saved search + the edited search
                const updatedSearches = currentSavedSearches.map((ea_search, index) => {
                    if (index === duplicateSearchIndex) {
                        return {
                            ...ea_search,
                            search_name: new_name,
                            parameters: {
                                ...ea_search.parameters,
                                search_name: new_name,
                                search_filters: {
                                    ...ea_search.parameters.search_filters,
                                    searchName: new_name,
                                    selectedSearchName: new_name,
                                },
                            },
                        };
                    }
                    return ea_search;
                });
                // console.log(
                //     'ClientListTableWithSearch: editSavedSearchNameFN: modified/edited object = ',
                //     updatedSearches.at(duplicateSearchIndex)
                // );

                // Update the state
                if (selectedSearchName === old_name) {
                    setSelectedSearchName(new_name);
                }
                setCurrentSavedSearches(updatedSearches);

                // Process the edited search
                const search_response = await userAPI.SaveSearch(
                    userID,
                    message,
                    appliesTo,
                    updatedSearches.at(duplicateSearchIndex)
                );

                if (search_response) {
                    toast.success(`Successfully edited ${old_name} to ${new_name}`, {
                        autoClose: 3000,
                    });
                }

                // console.log('ClientListTableWithSearch: editSavedSearchNameFN: search_response = ', search_response);
                getSavedSearches(appliesTo);
            }
        }
    };

    // Called when the user clicks the delete icon
    const handleDeleteSavedSearch = async (search_name, search_id, applies_to, user_id) => {
        let confirmationMessage = endsWithSearch(search_name)
            ? `Are you sure you want to delete the ${search_name}?`
            : `Are you sure you want to delete the ${search_name} saved search?`;
        const confirmation = window.confirm(confirmationMessage);

        if (confirmation) {
            const delete_response = await userAPI.DeleteSavedSearch(search_id, applies_to, user_id);
            if (delete_response) {
                const successMessage = endsWithSearch(search_name)
                    ? `Successfully deleted the ${search_name}`
                    : `Successfully deleted the ${search_name} saved search`;

                toast.success(successMessage, {
                    autoClose: 3000,
                });

                if (search_name === selectedSearchName) {
                    setSelectedSearchName('');
                    resetToDefaults();
                }
                getSavedSearches(appliesTo);
            } else {
                const errorMessage = endsWithSearch(search_name)
                    ? `Failed to delete the ${search_name}`
                    : `Failed to delete the ${search_name} saved search`;

                toast.error(errorMessage);
            }
            // console.log('ClientListTableWithSearch: deleteSavedSearchFN: delete_response = ', delete_response);
        }
    };

    // Saves the current set search parameters to storage
    const searchButtonClicked = () => {
        if (isNaN(ben)) {
            toast.error('BEN must be a number');
        } else {
            let sfv_obj = createSearchParametersObject();
            saveSearchFieldValuesJSONToStorage(JSON.stringify(sfv_obj));
            handleSearchButtonClick(sfv_obj);
        }
    };

    const saveSearchButtonClicked = () => {
        let sfv_obj = createSearchParametersObject();
        handleSaveSearch(sfv_obj);
        saveSearchFieldValuesJSONToStorage(JSON.stringify(sfv_obj));
    };

    const handleCancelButtonClick1 = () => {
        setShowTextField1(false);
        setIsDialogOpen1(false);
        setLanding(true);
    };

    const handleCancelButtonClick2 = () => {
        setShowTextField2(false);
        setIsDialogOpen2(false);
    };

    const handleKeyDown = (event) => {
        // Stops the select component from navigating while typing
        event.stopPropagation();
        if (event.key === 'Enter') {
            handleOnlySaveAsNewSearch2();
        }
    };

    const openDialog1 = (searchName) => {
        let confirmationMessage = endsWithSearch(searchName)
            ? `Do you want to update the ${searchName} or save as a new search?`
            : `Do you want to update the ${searchName} saved search or save as a new search?`;
        setDialogMessage(confirmationMessage);
        setIsDialogOpen1(true);
    };

    const openDialog2 = () => {
        setShowTextField2(true);
        setIsDialogOpen2(true);
    };

    const promptSaveNewSearch = () => {
        return (
            <Dialog open={isDialogOpen2} onClose={() => setIsDialogOpen2(false)}>
                <DialogContent>
                    {showTextField2 && (
                        <Box>
                            {'Please enter a name for the new saved search: '}
                            <TextField
                                autoFocus
                                margin='dense'
                                label='Search Name'
                                type='text'
                                fullWidth
                                value={textFieldValue}
                                onChange={(event) => setTextFieldValue(event.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                            <Box mt={2}>
                                <Button
                                    disabled={textFieldValue === ''}
                                    onClick={handleOnlySaveAsNewSearch2}
                                    color='primary'
                                >
                                    OK
                                </Button>
                                <Button onClick={handleCancelButtonClick2} color='primary'>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        );
    };
    //################################### END SAVED SEARCHES #############################################

    // const deadlineFieldsMapping = [
    //     { text: 'Client Start Date', value: 'start_date' },
    //     { text: 'Client End Date', value: 'end_date' },
    // ];
    // deadlineFieldsMapping.sort((a, b) => a.text.localeCompare(b.text));

    // const deadlineFieldsOperatorMapping = [
    //     { text: 'is x days past deadline', value: 'isBeforeXDays' },
    //     { text: 'is x days out from deadline', value: 'isAfterXDays' },
    // ];

    // const deadlineDaysOptions = [
    //     { text: '30', value: 30 },
    //     { text: '60', value: 60 },
    //     { text: '90', value: 90 },
    // ];

    // const handleInputChange = (event) => {
    //     const inputValue = event.target.value;
    //     if (inputValue === '') {
    //         setDeadlineValue('');
    //         handleDeadlineDaysChange('');
    //     } else {
    //         setDeadlineValue(inputValue);
    //         handleDeadlineDaysChange(inputValue);
    //     }
    // };

    // const handleSelect = (event, newValue) => {
    //     if (newValue !== null) {
    //         setDeadlineValue(newValue.value);
    //         handleDeadlineDaysChange(newValue.value);
    //     } else {
    //         setDeadlineValue('');
    //         handleDeadlineDaysChange('');
    //     }
    // };

    // Client Search Form
    return (
        <>
            <Accordion sx={{ marginBottom: '1em' }} onChange={handleAccordianOnChange}>
                <AccordionSummary
                    id='search-panel-header'
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='search-panel-content'
                    sx={{ m: 0 }}
                >
                    {accordianTitle}
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                onChange={(event) => setBen(event.target.value)}
                                value={ben}
                                label='BEN'
                                variant='outlined'
                                fullWidth
                                margin='dense'
                                size='small'
                            />

                            <TextField
                                onChange={(event) => setName(event.target.value)}
                                value={name}
                                label='Name'
                                variant='outlined'
                                fullWidth
                                margin='dense'
                                size='small'
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-primary-contact'
                                    selectedOptions={primaryContacts}
                                    options={primaryContactMSOptions}
                                    handleSelectChange={handlePrimaryContactChange}
                                    label='Primary Contact'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-secondary-contact'
                                    selectedOptions={secondaryContacts}
                                    options={secondaryContactMSOptions}
                                    handleSelectChange={handleSecondaryContactChange}
                                    label='Secondary Contact'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-applicant-cohort'
                                    selectedOptions={applicantCohorts}
                                    options={applicantCohortMSOptions}
                                    handleSelectChange={handleApplicantCohortChange}
                                    label='Applicant Cohorts'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='teams'
                                    selectedOptions={teamIDs}
                                    options={teamsMSOptions}
                                    handleSelectChange={handleTeamsChange}
                                    label='Teams'
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='cipa-compliance'
                                    selectedOptions={cipaCompliance}
                                    options={cipaComplianceMSOptions}
                                    handleSelectChange={handleCipaComplianceChange}
                                    label='CIPA Compliance'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='search-states'
                                    selectedOptions={states}
                                    options={statesMSOptions}
                                    handleSelectChange={handleStatesChange}
                                    label='Client State'
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense' size='small'>
                                <MultiAutoComplete
                                    id='services'
                                    selectedOptions={services}
                                    options={servicesMSOptions}
                                    handleSelectChange={handleServicesChange}
                                    label='Services'
                                />
                            </FormControl>

                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isAClient}
                                            onChange={(event) => setIsAClient(event.target.checked)}
                                        />
                                    }
                                    label='Is A Client'
                                />
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth={true} margin='normal'>
                                <TextField
                                    label='Client Start Date From'
                                    id='clientstartfromdate-textfield'
                                    value={clientStartDateFrom}
                                    type='date'
                                    onChange={(e) => {
                                        // console.log('ClientListTableWithSearch: clientStartDateFrom = ', e.target.value);
                                        const newValue = e.target.value;
                                        setClientStartDateFrom(newValue);

                                        if (newValue > clientStartDateTo || !clientStartDateTo) {
                                            setClientStartDateTo(newValue);
                                        }
                                    }}
                                    margin='dense'
                                    size='small'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                                <TextField
                                    label='Client Start Date To'
                                    id='clientstarttodate-textfield'
                                    value={clientStartDateTo}
                                    error={clientStartDateFrom > clientStartDateTo && clientStartDateTo !== ''}
                                    helperText={
                                        clientStartDateFrom > clientStartDateTo && clientStartDateTo !== ''
                                            ? 'To date is earlier than from date'
                                            : ''
                                    }
                                    type='date'
                                    onChange={(e) => {
                                        // console.log('ClientListTableWithSearch: clientStartDateTo = ', e.target.value);
                                        const newValue = e.target.value;
                                        setClientStartDateTo(newValue);
                                    }}
                                    margin='dense'
                                    size='small'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{ marginBottom: 5 }}
                                />

                                <TextField
                                    label='Client End Date From'
                                    id='clientenddatefrom-textfield'
                                    value={clientEndDateFrom}
                                    type='date'
                                    onChange={(e) => {
                                        // console.log('ClientListTableWithSearch: clientEndDateFrom = ', e.target.value);
                                        const newValue = e.target.value;
                                        setClientEndDateFrom(newValue);

                                        if (newValue > clientEndDateTo || !clientEndDateTo) {
                                            setClientEndDateTo(newValue);
                                        }
                                    }}
                                    margin='dense'
                                    size='small'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                                <TextField
                                    label='Client End Date To'
                                    id='clientenddateto-textfield'
                                    value={clientEndDateTo}
                                    error={clientEndDateFrom > clientEndDateTo && clientEndDateTo !== ''}
                                    helperText={
                                        clientEndDateFrom > clientEndDateTo && clientEndDateTo !== ''
                                            ? 'To date is earlier than from date'
                                            : ''
                                    }
                                    type='date'
                                    onChange={(e) => {
                                        // console.log('ClientListTableWithSearch: clientEndDateTo = ', e.target.value);
                                        const newValue = e.target.value;
                                        setClientEndDateTo(newValue);
                                    }}
                                    margin='dense'
                                    size='small'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{ marginBottom: 5 }}
                                />

                                {/* Deadline Dropdowns */}
                                {/* <div style={{ position: 'relative' }}> */}
                                {/* <Accordion
                                        label={
                                            valueInSearchRanges && isDeadlineAccordionSelected
                                                ? 'Date ranges still in use'
                                                : ''
                                        }
                                        style={{
                                            // border: isDeadlineAccordionSelected ? '2px solid #4CAF50' : '',
                                            border:
                                                valueInSearchRanges && isDeadlineAccordionSelected
                                                    ? '2px solid #FF0000'
                                                    : isDeadlineAccordionSelected
                                                    ? '2px solid #4CAF50'
                                                    : '',
                                        }}
                                        sx={{ padding: '0px' }}
                                    > */}
                                {/* <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='qa-fields'
                                            id='qa-fields'
                                            // sx={{ mt: 1, mb: 0.7 }}
                                            sx={{ mt: 0.5, mb: 0.5 }}
                                        >
                                            <Typography color='textSecondary'>
                                                {isDeadlineAccordionSelected
                                                    ? `(${deadlineFieldsCount}) Deadline Field${
                                                          deadlineFieldsCount > 1 ? 's' : ''
                                                      } Selected`
                                                    : 'Deadline Fields'}
                                            </Typography>
                                        </AccordionSummary> */}
                                {/* <AccordionDetails sx={{ padding: '8px' }}> */}
                                {/* <FormControl fullWidth margin='dense' size='small'>
                                                <Autocomplete
                                                    id='deadline-field-options'
                                                    options={deadlineFieldsMapping}
                                                    getOptionLabel={(option) => (option.text ? option.text : '')}
                                                    value={deadlineFieldName}
                                                    isOptionEqualToValue={(option, value) => {
                                                        // console.log(option, value);
                                                        return option.value === value.value;
                                                    }}
                                                    onChange={(event, newValue) => {
                                                        handleDeadlineFieldStatusChange(newValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label='Date Fields' variant='outlined' />
                                                    )}
                                                />
                                            </FormControl> */}

                                {/* Before or After Deadline */}
                                {/* <FormControl fullWidth margin='dense' size='small'>
                                                <Autocomplete
                                                    id='deadline-field-operator-options'
                                                    options={deadlineFieldsOperatorMapping}
                                                    getOptionLabel={(option) => (option.text ? option.text : '')}
                                                    value={deadlineFieldOperator}
                                                    isOptionEqualToValue={(option, value) => {
                                                        // console.log(option, value);
                                                        return option.value === value.value;
                                                    }}
                                                    onChange={(event, newValue) => {
                                                        handleDeadlineOperatorStatusChange(newValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label='Operators' variant='outlined' />
                                                    )}
                                                />
                                            </FormControl> */}

                                {/* <FormControl fullWidth margin='dense' size='small'>
                                                <Autocomplete
                                                    freeSolo
                                                    options={deadlineDaysOptions}
                                                    getOptionLabel={(option) => option.text || ''}
                                                    value={
                                                        deadlineDaysOptions.find(
                                                            (option) => option.value === deadlineValue
                                                        ) || null
                                                    }
                                                    isOptionEqualToValue={(option, value) => option.value === value}
                                                    onChange={handleSelect}
                                                    onInputChange={(event, value) => {
                                                        // Handles the direct text input from renderInput
                                                        if (value === '') {
                                                            setDeadlineValue('');
                                                            handleDeadlineDaysChange('');
                                                        } else {
                                                            setDeadlineValue(value);
                                                            handleDeadlineDaysChange(value);
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            id='amount-of-days'
                                                            label='Amount of Days'
                                                            type='number'
                                                            value={deadlineValue}
                                                            onChange={handleInputChange}
                                                            variant='outlined'
                                                        />
                                                    )}
                                                />
                                            </FormControl> */}
                                {/* </AccordionDetails>
                                    </Accordion> */}
                                {/* {valueInSearchRanges && isDeadlineAccordionSelected && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '-12px',
                                                backgroundColor: '#FF0000',
                                                color: '#FFFFFF',
                                                padding: '2px 5px',
                                                // borderRadius: '3px',
                                                fontSize: '0.875rem',
                                                transform: 'translateY(-50%)',
                                            }}
                                        >
                                            Date ranges are in use
                                        </div>
                                    )} */}
                                {/* </div> */}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='space-between' width='100%'>
                            <Box flex='1' maxWidth='340.2px' mr={2}>
                                <FormControl fullWidth={true} margin='normal'>
                                    <InputLabel
                                        id='ercAppStatus-label'
                                        shrink={true}
                                        sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                                    >
                                        Saved Searches
                                    </InputLabel>
                                    <Select
                                        id='currentSavedSearches-select'
                                        labelId='currentSavedSearches-label'
                                        label='Current Saved Searches'
                                        value={selectedSearchName ?? ''}
                                        renderValue={(selected) => (selected ? selected : 'Select a search')}
                                        onChange={(event) => {
                                            handleSelectSavedSearch(event.target.value);
                                        }}
                                        onOpen={() => setIsSelectOpen(true)}
                                        onClose={() => setIsSelectOpen(false)}
                                    >
                                        <MenuItem value='' onClick={resetToDefaults}>
                                            Default
                                        </MenuItem>
                                        {currentSavedSearches.map((ea_search, index) => {
                                            if (editingSearchName === ea_search.search_name) {
                                                return (
                                                    <EditableMenuItem
                                                        key={index}
                                                        ea_search={ea_search}
                                                        onSave={(oldName, newName) => {
                                                            handleEditSavedSearchName(oldName, newName);
                                                            setEditingSearchName(null);
                                                        }}
                                                        onCancel={() => {
                                                            setEditingSearchName(null);
                                                        }}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <MenuItem key={index} value={ea_search.search_name}>
                                                        {ea_search.search_name}
                                                        {isSelectOpen && (
                                                            <>
                                                                <Tooltip title='Edit Name'>
                                                                    <IconButton
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            setEditingSearchName(ea_search.search_name);
                                                                        }}
                                                                        size='small'
                                                                        style={{ marginLeft: '8px' }}
                                                                    >
                                                                        <EditIcon fontSize='inherit' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title='Delete'>
                                                                    <IconButton
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            handleDeleteSavedSearch(
                                                                                ea_search.search_name,
                                                                                ea_search.search_id,
                                                                                appliesTo,
                                                                                userID
                                                                            );
                                                                        }}
                                                                        size='small'
                                                                        style={{ marginLeft: '8px' }}
                                                                    >
                                                                        <DeleteIcon fontSize='inherit' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                    </MenuItem>
                                                );
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Grid
                                item
                                xs={12}
                                md={7}
                                container
                                justifyContent='flex-start'
                                spacing={2}
                                marginTop={'1em'}
                            >
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        onClick={searchButtonClicked}
                                        disabled={
                                            // (valueInSearchRanges && isDeadlineAccordionSelected) ||
                                            (clientStartDateFrom > clientStartDateTo && clientStartDateTo) ||
                                            (clientEndDateFrom > clientEndDateTo && clientEndDateTo !== '')
                                        }
                                        sx={{ backgroundColor: '#4CAF50' }}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' onClick={saveSearchButtonClicked}>
                                        {selectedSearchName ? 'Update Search' : 'Save Search'}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' color='secondary' onClick={resetToDefaults}>
                                        Reset to Defaults
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <PromptUpdateOrSaveNewSearchDialog
                isDialogOpen1={isDialogOpen1}
                setIsDialogOpen1={setIsDialogOpen1}
                landing={landing}
                dialogMessage={dialogMessage}
                handleOnlyUpdateSearch={handleOnlyUpdateSearch}
                handleOnlySaveAsNewSearch1={handleOnlySaveAsNewSearch1}
                handleCancelButtonClick1={handleCancelButtonClick1}
                showTextField1={showTextField1}
                textFieldValue={textFieldValue}
                setTextFieldValue={setTextFieldValue}
                handleKeyDown={handleKeyDown}
                handleOnlySaveAsNewSearch2={handleOnlySaveAsNewSearch2}
            />
            {promptSaveNewSearch()}
        </>
    );
}
export default ClientListTableWithSearch;
